﻿import React from 'react';
import { IPage } from './app';
import { LoadCart } from './products';
import { HeaderNav } from './headernav';
import { Footer } from './footer';
import { HeroHeader } from './common';

export function Eula({ routeOnClick, products, version }: IPage) {
    const cartState = LoadCart(products, version);
    return (
        <>
            <HeaderNav routeOnClick={routeOnClick} cartState={cartState} shoppingEnabled />
            <HeroHeader heroCategory="Legal" heroText="End User License Agreement" />
            <br />
            <div className="w-75 center-element">
                <p><b>Important – Read Carefully.</b></p>
                <p><span style={{ fontWeight: 400 }}>This D365Licenses.com End-User License Agreement (“Agreement”) is a legal agreement between you (“End-User”) (on the one hand) and D365Licenses.com. (“D365Licenses.com”) and its OEM partner(s) (“OEM”) (on the other hand), for the software, which includes computer software and may include associated media, printed materials, and online or electronic documentation (the “Product”).</span></p>
                <p><span style={{ fontWeight: 400 }}>By installing, copying, other otherwise using this Product, you agree to be bound by the terms of this Agreement. If you, the End-User, do not agree to the terms of this Agreement, do not install or use this Product; you may, however, return it to your place of purchase for a refund or credit. This license is not a sale. Title and copyrights to the Product remain with D365Licenses.com and its OEM partner(s).</span></p>
                <p><span style={{ fontWeight: 400 }}>Unauthorized copying of the data, or failure to comply with the provisions of this License Agreement, will result in automatic termination of this license and D365Licenses.com and its OEM partner(s) may use any other legal remedies available to it.</span></p>
                <p><span style={{ fontWeight: 400 }}>IN THE EVENT OF LICENSE TERMINATION, ALL MATERIALS, DATABASES, AND DOCUMENTATION MUST BE IMMEDIATELY RETURNED TO D365Licenses.com LLC AT THE ADDRESS LISTED AT THE END OF THIS AGREEMENT.</span></p>
                <ol>
                    <li><span style={{ fontWeight: 400 }}>End-User represents and warrants that it is authorized and empowered to enter into this Agreement. D365Licenses.com represents and warrants that it is authorized and empowered to grant the rights hereinafter set forth.</span></li>
                    <li><span style={{ fontWeight: 400 }}>D365Licenses.com and its OEM Partner(s) hereby grant End-User a non-exclusive, non-transferable right to use the Product, subject to the use restrictions and limitations set forth below.</span></li>
                    <li><span style={{ fontWeight: 400 }}>D365Licenses.com shall provide End-User with one (1) machine-readable copy of the Product. This license authorizes use of the Product at a single location, which shall mean a single location which uses or accesses the Product either from a computer or terminal on site or through a computer or terminal at a supporting location.</span></li>
                    <li><span style={{ fontWeight: 400 }}>End-User acknowledges that the Product is confidential, proprietary material owned and copyrighted by D365Licenses.com. End-User agrees that D365Licenses.com and its OEM partner(s) shall retain exclusive ownership of the Product, including all literary property rights, patents, copyrights, trademarks, trade secrets, trade names, or service marks, including goodwill and that D365Licenses.com may enforce such rights directly against End-User in the event the terms of this Agreement are violated.  </span></li>
                    <li><span style={{ fontWeight: 400 }}>The Product is intended for use solely by End-User for their own internal purposes as an alternative electronic information source of data. The Product may only be used at the location(s) licensed and paid for by End-User to the D365Licenses.com. End-User agrees not to copy, modify, sub-license, assign, transfer or resell the Product, in whole or in part. End-User agrees not to translate, reverse engineer, decompile, disassemble, or make any attempt to discover the source code of the Product (except and only to the extent applicable law prohibits such restrictions). End-User further agrees not to download/upload the Product, in whole or in part, or to establish a network, place data on the Internet or otherwise publish, or offer a service bureau utilizing the Product. End-User agrees to restrict access to the Product to designated employees and to use its best efforts to prevent violation of these restrictions by agents, employees and others, taking such steps and reasonable security precautions as may be necessary. End-User shall permit D365Licenses.com and/or its representative access to its premises during normal business hours to verify compliance with the provisions of this Agreement. </span></li>
                    <li><span style={{ fontWeight: 400 }}>Without prejudice to any other rights, D365Licenses.com may terminate this Agreement if End-User does not abide by the terms and conditions of this Agreement. Upon termination of this Agreement, all licenses and rights to use the Product shall immediately terminate and End-User shall immediately cease any and all use of the Product. Within thirty (30) days after termination of the Agreement, End-User shall return to D365Licenses.com, postage prepaid, all copies of the Product. In the event that the End-User fails to pay the periodic maintenance fee, unless such periodic maintenance fee is waived in writing by D365Licenses.com, then D365Licenses.com shall no longer be obligated to provide any services or support to End-User nor shall D365Licenses.com be obligated to provide any additional upgrades or updates for such Product or any other service or Product. D365Licenses.com reserves the right to discontinue any services provided to End-User or made available to End-User through the use of the Product.  </span></li>
                    <li><span style={{ fontWeight: 400 }}>All UPDATES provided by D365Licenses.com and its affiliates shall be considered part of the Product and subject to the terms and conditions of this Agreement. Additional license terms may accompany UPDATES. By installing, copying, or otherwise using any UPDATE, End-User agrees to be bound by this Agreement and any terms accompanying each such UPDATE. If End-User does not agree to the additional license terms accompanying such UPDATES, do not install, copy, or otherwise use such UPDATES.  </span></li>
                    <li><span style={{ fontWeight: 400 }}>End-User agrees that D365Licenses.com and its affiliates may collect and use information End-User provides as a part of support services, problem resolution or technical improvements or developments in connection with the Product.  </span></li>
                    <li><span style={{ fontWeight: 400 }}>End-User acknowledges that the Product is of U.S. origin and agrees to comply with all applicable international and national laws that apply to the Product, including the U.S. Export Administration Regulations, as well as end-user, end-use and destination restrictions issued by U.S. and other governments.  </span></li>
                    <li><span style={{ fontWeight: 400 }}>D365Licenses.com REPRESENTS THAT THE PRODUCT DOES NOT VIOLATE OR INFRINGE ANY PATENT, TRADEMARK, TRADE SECRET, COPYRIGHT, OR SIMILAR RIGHT. IN THE EVENT THE PRODUCT IS HELD TO INFRINGE THE RIGHTS OF ANY THIRD PARTY, D365Licenses.com SHALL HAVE THE OPTION EITHER TO PROCURE THE RIGHT FOR THE END-USER TO CONTINUE USING THE PRODUCT OR AT D365Licenses.com'S EXPENSE, TO REPLACE OR MODIFY THE PRODUCT SO THAT IT BECOMES NON-INFRINGING. D365Licenses.com AND ITS OEM PARTNER(S) MAKE NO OTHER WARRANTY, EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, THE ACCURACY OF THE PRODUCT OR THE MERCHANTABILITY AND FITNESS OF THE PRODUCT FOR A PARTICULAR PURPOSE. FURTHER, D365Licenses.com DOES NOT WARRANT THE COMPATIBILITY OF THE PRODUCT WITH END-USER'S COMPUTER HARDWARE AND/OR SOFTWARE SYSTEM.  </span></li>
                    <li><span style={{ fontWeight: 400 }}>End-User's sole and exclusive remedy for any damage or loss in any way connected with the Product furnished herein, whether by breach of warranty, negligence, or any breach of any other duty, shall be, at D365Licenses.com’ option, replacement of the Product or return or credit of an appropriate portion of any payment made by End-User with respect to such Product. Under no circumstances shall D365Licenses.com or its OEM Partner(s) be liable to End-User or any other person for any indirect, incidental, special or consequential damages of any kind, however, caused and regardless of theory of liability, including, without limitation, for lost profits, loss of data, loss of goodwill, business interruption, computer failure or malfunction or any and all other commercial damages or losses. This limitation will apply even if D365Licenses.com has been advised of the possibility of such damage. Some jurisdictions do not allow the exclusion of a limitation of indirect, incidental, special or consequential damages, so the above exclusion may not apply to such End-Users. In such event, D365Licenses.com’ total liability to End-User for all damages shall be limited to the amount of twenty-five dollars ($25.00).  </span></li>
                    <li><span style={{ fontWeight: 400 }}>D365Licenses.com may cancel this license at any time if End-User fails to comply with the terms and conditions of this Agreement; and D365Licenses.com may obtain injunctive relief and may enforce any other rights and remedies to which it may be entitled in order to protect and preserve its proprietary rights.  </span></li>
                    <li><span style={{ fontWeight: 400 }}>This Agreement is the complete and exclusive statement of the understanding between the parties, with respect to the subject matter, superseding all other agreements, representations, communications, advertisements, statements and proposals, oral or written.  </span></li>
                    <li><span style={{ fontWeight: 400 }}>No term or provision hereof shall be deemed waived, and no breach excused, unless such waiver or consent shall be in writing and signed by the party claimed to have waived or consented. Any consent by any party to, or waiver of, a breach by the other, whether express or implied, shall not constitute a consent to, waiver of, or excuse for any other different or subsequent breach.  </span></li>
                </ol>
                <p><span style={{ fontWeight: 400 }}>General</span></p>
                <p><span style={{ fontWeight: 400 }}>The internal laws of the State of California shall govern this Agreement. If any provision of the Agreement is held invalid, the remainder of the Agreement shall continue in full force and effect. If you have any questions, please contact in writing: D365Licenses.com, LLC, 556 S. Fair Oaks, #101-425, Pasadena, CA 91105.</span></p>
            </div>
            <br />
            <Footer routeOnClick={routeOnClick} />
        </>
    );
}