﻿import React from 'react';
import { IPage } from './app';
import { HeaderNav } from './headernav';
import { LoadCart } from './products';
import { Footer } from './footer';
import { AnnouncementRibbon, EmailLink } from './common';

export function Instructional({ routeOnClick, products, version }: IPage) {
    const cartState = LoadCart(products, version);
    return (
        <>
            <HeaderNav routeOnClick={routeOnClick} cartState={cartState} shoppingEnabled />
            <div className="text-white background-blue">
                <br />
                <br />
                <div className="mx-5">
                    <p className="display-1"><b>What's the process?</b></p>
                    <p className="h3">Easy as 1, 2, 3.</p>
                </div>
                <br />
            </div>
            <br />
            <br />
            <br />
            <div className="w-75 center-element">
                <InstructionalBlock
                    number={1}
                    color="#DD6060"
                    title="Purchasing"
                    steps={["Purchase licenses on our purchase page", "Look for an email from us"]}
                />
                <InstructionalBlock
                    number={2}
                    color="#039FA4"
                    title="Verifying your licenses"
                    steps={["Have your CRM admin log into admin.microsoft.com", "On the let side, select Billing, and then Licenses to verify"]}
                />
                <InstructionalBlock
                    number={3}
                    color="#FFAD0D"
                    title="Assigning"
                    steps={["Have your CRM admin log into admin.microsoft.com", "Select Users > Active users. Choose a user and then select Manage product licenses."]}
                />
            </div>
            <br />
            <br />
            <br />
            <AnnouncementRibbon width="50%">
                <b>Remember: </b> Reach out with any questions or concerns at any time, we are here to help. For help, please email <EmailLink />
            </AnnouncementRibbon>
            <br />
            <Footer routeOnClick={routeOnClick} />
        </>
    );
}

function InstructionalBlock({ number, color, title, steps }: { number: number; color: string; title: string; steps: string[] }) {
    return (
        <div className="d-flex">
            <p className="me-4 text-white border rounded-circle display-4 center-text"
                style={{ width: "70px", height: "70px", backgroundColor: color }}>
                <b>{number}</b>
            </p>
            <div>
                <p className="support-text-blue h4"><b>{title}</b></p>
                <ul>
                    {steps.map(step => (<li>{step}</li>)) }
                </ul>
            </div>
        </div>
    );
}