﻿import React from 'react';
import { ProductState, ProductStates, getProductCount } from './products.ts';
import { RouteOnClick } from './app.tsx';
import { identity, sumf } from './util.ts';
import { ShoppingCartButton, ShoppingCartIcon } from './common.tsx';

type IHeaderNav = React.PropsWithChildren<{
    cartState: ProductStates;
    shoppingEnabled: boolean;
    routeOnClick: RouteOnClick;
}>
export function HeaderNav({ cartState, shoppingEnabled, routeOnClick, children }: IHeaderNav) {
    return (
        <header className="d-flex flex-wrap justify-content-center py-3 mb-4 border-bottom headerNav" >
            <a style={{ marginLeft: '10rem' }} href="/" onClick={routeOnClick} className="d-flex align-items-center mb-3 mb-md-0 me-md-auto link-body-emphasis text-decoration-none cursor-hover">
                <img src="/images/LogoDarkBg.svg" className="" alt="D365 Licenses Logo" style={{ height: '6rem', objectFit: 'cover' }} />
            </a>
            <ul style={{ marginRight: '10rem' }} className="nav nav-pills">
                <li className="nav-item">{children}</li>
                <li className="mt-2 mx-2 nav-item">
                    <a className="md-2 headerButton headerLink" href="/home/service" onClick={routeOnClick}>
                        <b>Support</b>
                    </a>
                </li>
                <li className="mt-2 nav-item">
                    <a className="my-2 headerButton headerLink" href="/home/instructional" onClick={routeOnClick}>
                        <b>What to expect</b>
                    </a>
                </li>
                <li className="nav-item">
                    <ShoppingCartButton productCount={getProductCount(cartState)} shoppingEnabled={shoppingEnabled} routeOnClick={routeOnClick} />
                </li>
            </ul>
        </header>
    );
}