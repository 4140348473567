﻿import React from 'react';
import { IPage } from './app';
import { LoadCart } from './products';
import { HeaderNav } from './headernav';
import { Footer } from './footer';
import { HeroHeader } from './common';

export function PrivacyPolicy({ routeOnClick, products, version }: IPage) {
    const cartState = LoadCart(products, version);
    return (
        <>
            <HeaderNav routeOnClick={routeOnClick} cartState={cartState} shoppingEnabled />
            <HeroHeader heroCategory="Legal" heroText="Privacy Policy"/>
            <br />
            <div className="w-75 center-element">
                <p><span style={{ fontWeight: 400 }}>Thank you for choosing to be part of our community at D365Licenses.com (“D365Licenses.com”, “we”, “us”, or “our”). We are committed to protecting your personal information and your right to privacy. If you have any questions or concerns about our policy, or our practices with regards to your personal information, please contact us at Info@D365Licenses.com.</span></p>
                <p><span style={{ fontWeight: 400 }}>In this privacy notice, we describe our privacy policy. We seek to explain to you in the clearest way possible what information we collect, how we use it and what rights you have in relation to it. If there are any terms in this privacy policy that you do not agree with, please discontinue use of our Sites and our services. It is D365Licenses.com’s policy to comply with all applicable privacy and data protection laws. This commitment reflects the value we place on earning and keeping the trust of our employees, customers, business partners and others who share their personal information with us.</span></p>
                <p><span style={{ fontWeight: 400 }}>This Privacy Policy (this “Policy”) describes how D365Licenses.com protects your privacy when we collect personal information on D365Licenses.com Internet sites (such as D365Licenses.com). As used in this Policy, the term “personal information” means information that identifies you personally, alone or in combination with other information available to us.</span></p>
                <p><b>Your Consent</b></p>
                <p><span style={{ fontWeight: 400 }}>By visiting a D365Licenses.com website or providing personal information to us, you are consenting to the collection, use and disclosure of your personal information as described in this Policy. If you do not consent to the collection, use and disclosure of your personal information as described in this Policy (and any applicable Country or Web Site Privacy Statements), do not use D365Licenses.com Internet Sites, contact D365Licenses.com, or Submit Data to D365Licenses.com via Internet form.</span></p>
                <p><b>Please read this privacy policy carefully as it will help you make informed decisions about sharing your personal information with us.</b></p>
                <p><b>Limitations on the Collection, Use, and Disclosure of Personal Information</b></p>
                <p><span style={{ fontWeight: 400 }}>To the extent required by applicable law, whenever D365Licenses.com collects personal information on a D365Licenses.com Internet Site, D365Licenses.com will:</span></p>

                <ul>
                    <li><span style={{ fontWeight: 400 }}> provide timely and appropriate notice to you about its data practices</span><span style={{ fontWeight: 400 }}><br />
                    </span><span style={{ fontWeight: 400 }}>• collect, use, disclose and transfer your personal information only with your consent, which may be express or implied, depending on the sensitivity of the personal information, legal requirements, and other factors</span><span style={{ fontWeight: 400 }}><br />
                        </span><span style={{ fontWeight: 400 }}>• collect your personal information only for specific, limited purposes. The information we collect will be relevant, adequate and not excessive for the purposes for which it is collected</span><span style={{ fontWeight: 400 }}><br />
                        </span><span style={{ fontWeight: 400 }}>• process your personal information in a manner consistent with the purposes for which it was originally collected or to which you have subsequently consented</span><span style={{ fontWeight: 400 }}><br />
                        </span><span style={{ fontWeight: 400 }}>• take commercially reasonable steps to ensure that your personal information is reliable for its intended use, accurate, complete, and, where necessary, kept up-to-date</span></li>
                </ul>
                <ol>
                    <li><b> WHAT INFORMATION DO WE COLLECT?</b></li>
                </ol>
                <p><b>Personal Information you disclose to us.</b></p>
                <p><b>In short: </b><span style={{ fontWeight: 400 }}>We collect personal information that you provide to us such as name, address, contact information, job title, company details, and payment information.</span></p>
                <p><span style={{ fontWeight: 400 }}>We collect personal information that you voluntarily provide to us when expressing an interest in obtaining information about us or our products and services, when participating in activities on the Sites or otherwise contacting us.</span></p>
                <p><span style={{ fontWeight: 400 }}>The personal information that we collect depends on the context of your interactions with us and the Sites, the choices you make and the products and features you use. The personal information we collect can include the following:</span></p>
                <p><b>Name and Contact Data. </b><span style={{ fontWeight: 400 }}>We collect your first and last name, email address, phone number, and other similar contact data.</span></p>
                <p><b>Payment Data. </b><span style={{ fontWeight: 400 }}>We collect data necessary to process your payment if you make purchases, such as a credit card number, and the security code associated with your payment instrument. All payment data is stored by our payment processor, and you should review its privacy policies and contact the payment processor directly to respond to your questions.</span></p>
                <p><span style={{ fontWeight: 400 }}>All personal information that you provide to us must be true, complete and accurate, and you must notify us of any changes to such personal information.</span></p>
                <p><b>Information automatically collected.</b></p>
                <p><b>In Short: </b><span style={{ fontWeight: 400 }}>Some information – such as IP address and/or browser and device characteristics is automatically collected when you visit our Sites.</span></p>
                <p><span style={{ fontWeight: 400 }}>Like many businesses, when you access and use a D365Licenses.com Site, there are 3 ways we may collect information about you:</span></p>

                <ol>
                    <li><span style={{ fontWeight: 400 }}> Information Sent to Us by Your Web Browser</span></li>
                </ol>
                <p><span style={{ fontWeight: 400 }}>D365Licenses.com collects information that is sent to us automatically by your web browser. This information typically includes the IP address of your Internet service provider, the name of your operating system, browser and device characteristics, referring URLs, device name, country, location, information about how and when you use our Sites and other technical information. The information we receive depends on the settings on your web browser. Please check your browser if you want to learn what information your browser sends or how to change your settings. The information provided by your browser does not identify you personally. We use this information to create statistics that help us improve our sites and make them more compatible with the technology used by our Internet visitors.</span></p>

                <ol start={2}>
                    <li><span style={{ fontWeight: 400 }}> Information You Knowingly and Voluntarily Provide</span></li>
                </ol>
                <p><span style={{ fontWeight: 400 }}>D365Licenses.com collects the information you knowingly and voluntarily provide when you use an D365Licenses.com Internet Site, for example, the information you provide when you sign up to receive email alerts, when you complete a survey, or when you ask us a question or email us with feedback. In many cases, this information will be personal information. D365Licenses.com uses this information for the purposes for which you provide it. We may also use the information we collect on D365Licenses.com Internet Sites for various business purposes such as customer service, fraud prevention, market research, improving our products and services, and providing you and your company with information and offers we believe may be of interest to you. We may also remove all the personally identifiable information and use the rest for historical, statistical or scientific purposes.</span></p>
                <p><b>Information collected from other sources.</b></p>
                <p><b>In Short: </b><span style={{ fontWeight: 400 }}>We may collect limited data from public databases, marketing partners, and other outside sources.</span></p>
                <p><span style={{ fontWeight: 400 }}>We may obtain information about you from other sources, such as public databases, joint marketing partners, as well as other third parties. Examples of the information we receive from other sources include: social media profile information, marketing leads and search results, and links, including paid listings (such as sponsored links).</span></p>
                <p><b>Links to Third Party Internet Sites</b></p>
                <p><span style={{ fontWeight: 400 }}>D365Licenses.com Internet Sites may contain links to Internet sites that are not operated by D365Licenses.com. These links are provided as a service and do not imply any endorsement of the activities or content of these sites, nor any association with their operators. D365Licenses.com does not control these Internet sites and is not responsible for their content, security, or privacy practices. We urge you to review the privacy policy posted on websites you visit before using the site or providing personal information.</span></p>

                <ol start={2}>
                    <li><b> HOW DO WE USE YOUR INFORMATION?</b></li>
                </ol>
                <p><b>In Short: </b><span style={{ fontWeight: 400 }}>We process your information for purposes based on legitimate business interests, the fulfillment of our contract with you, compliance with our legal obligations, and/or your consent.</span></p>
                <p><span style={{ fontWeight: 400 }}>We use personal information collected via our Sites for a variety of business purposes described below. We process your personal information for these purposes in reliance on our legitimate business interests (“Business Purposes”), in order to enter into or perform a contract with you (“Contractual”), with your consent (“Consent”), and/or for compliance with our legal obligations (“Legal Reasons”). We indicate the specific processing grounds we rely on next to each purpose listed below.</span></p>
                <p><b>We use the information we collect or receive:</b></p>

                <ul>
                    <li style={{ fontWeight: 400 }} aria-level={1}><b>To send you marketing and promotional communications </b><span style={{ fontWeight: 400 }}>for Business Purposes. We and/or our third-party marketing partners may use the personal information you send to us for our marketing purposes, if this is in accordance with your marketing preferences. You can opt-out of our marketing emails at any time.</span></li>
                    <li style={{ fontWeight: 400 }} aria-level={1}><b>To send administrative information to you </b><span style={{ fontWeight: 400 }}>for Business Purposes and/or Legal Reasons. We may use your personal information to send you product, service, and new feature information and/or information about changes to our terms, conditions, and policies.</span></li>
                    <li style={{ fontWeight: 400 }} aria-level={1}><b>Deliver targeted advertising to you </b><span style={{ fontWeight: 400 }}>for our Business Purposes. We may use your information to develop and display content and advertising (and work with third parties who do so) tailored to your interests and/or location and to measure its effectiveness.</span></li>
                    <li style={{ fontWeight: 400 }} aria-level={1}><b>Request feedback</b><span style={{ fontWeight: 400 }}> for our Business Purposes. We may use your information to request feedback and to contact you about your use of our Sites.</span></li>
                    <li style={{ fontWeight: 400 }} aria-level={1}><b>To protect our Sites </b><span style={{ fontWeight: 400 }}>for our Business Purposes. We may use your information as part of our efforts to keep our Sites safe and secure.</span></li>
                    <li style={{ fontWeight: 400 }} aria-level={1}><b>To enforce our terms, conditions, and policies </b><span style={{ fontWeight: 400 }}>for Business Purposes and/or for Legal Reasons.</span></li>
                    <li style={{ fontWeight: 400 }} aria-level={1}><b>To respond to legal requests and prevent harm</b><span style={{ fontWeight: 400 }}> for Legal Reasons. If we receive a subpoena or other legal request, we may need to inspect the data we hold to determine how to respond.</span></li>
                    <li style={{ fontWeight: 400 }} aria-level={1}><b>For other Business Purposes. </b><span style={{ fontWeight: 400 }}>We may use your information for other Business Purposes, such as data analysis, identifying usage trends, determining the effectiveness of our promotional campaigns and to evaluate and improve our Sites, products, services, marketing, and your experience.</span></li>
                </ul>
                <ol start={3}>
                    <li><b> WILL YOUR INFORMATION BE SHARED WITH ANYONE?</b></li>
                </ol>
                <p><b>In Short: </b><span style={{ fontWeight: 400 }}>We only share information with your consent, to comply with laws, to protect your rights, or to fulfill business obligations.</span></p>
                <p><span style={{ fontWeight: 400 }}>D365Licenses.com will never sell personal information collected from its Internet sites to mailing list brokers or other third parties. D365Licenses.com may share your personal information with third parties we hire to perform services for us. These third parties are required to use the personal information we share with them only to perform services on our behalf and to treat your personal information as strictly confidential. In some cases, D365Licenses.com may share your personal information with third parties who partner with us to provide products and services to our customers. When we do so, we will require our business partners to use the personal information we share with them in a manner consistent with the purposes for which it was originally collected (or to which you subsequently consented) and only as permitted under this Policy, any applicable Country or Web Site Privacy Statements, and all applicable privacy and data protection laws. In certain, limited circumstances we may share or transfer personal information to unrelated third parties.</span></p>
                <p><span style={{ fontWeight: 400 }}>For example, we may provide personal information to a third party for the following reasons:</span></p>

                <ul>
                    <li style={{ fontWeight: 400 }} aria-level={1}><span style={{ fontWeight: 400 }}>at your request.</span></li>
                    <li style={{ fontWeight: 400 }} aria-level={1}><span style={{ fontWeight: 400 }}>to comply with a legal requirement or court order.</span></li>
                    <li style={{ fontWeight: 400 }} aria-level={1}><span style={{ fontWeight: 400 }}>to investigate a possible crime, such as identity theft.</span></li>
                    <li style={{ fontWeight: 400 }} aria-level={1}><span style={{ fontWeight: 400 }}>in connection with the sale, purchase, merger, reorganization, liquidation or dissolution of D365Licenses.com or a D365Licenses.com business unit.</span></li>
                    <li style={{ fontWeight: 400 }} aria-level={1}><span style={{ fontWeight: 400 }}>under similar circumstances. If such an event occurs, we will take appropriate steps to protect your personal information.</span></li>
                </ul>
                <ol start={4}>
                    <li><b> DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?</b></li>
                </ol>
                <p><b>In Short: </b><span style={{ fontWeight: 400 }}>Yes, we use cookies and other tracking technologies to collect and store your information.</span></p>
                <p><span style={{ fontWeight: 400 }}>Information Collected by Placing a “Cookie” On Your Computer</span></p>
                <p><span style={{ fontWeight: 400 }}>D365Licenses.com may obtain information about you by installing a “tag” on your computer’s hard drive. This tag is known as a “cookie.” All D365Licenses.com Internet Sites use “session cookies.” A session cookie is used to tag your computer with a computer-generated, unique identifier when you access our site. A session cookie does not identify you personally and expires after you close your browser. We use session cookies to collect statistical information about the ways visitors use our sites’, which pages they visit, which links they use, and how long they stay on each page. We analyze this information in statistical form to better understand our visitor’s interests and needs to improve the content and functionality of our sites. Some D365Licenses.com Internet sites also use “persistent cookies.” These cookies do not expire when you close your browser; they stay on your computer until you delete them. By assigning your computer a unique identifier, we are able to create a database of your previous choices and preferences, and in situations where these choices or preferences need to be collected again, they can be provided by us automatically, saving you time and effort. If you do not wish to receive cookies, you may set your browser to reject cookies or to alert you when a cookie is placed on your computer. Although you are not required to accept cookies when you visit an D365Licenses.com Internet Site, you may be unable to use all of the functionality of the site if your browser rejects our cookies.</span></p>

                <ol start={5}>
                    <li><b> HOW LONG DO WE KEEP YOUR INFORMATION?</b></li>
                </ol>
                <p><b>In Short: </b><span style={{ fontWeight: 400 }}>We keep your information for as long as necessary to fulfill the purposes outlined in this privacy policy unless otherwise required by law.</span></p>
                <p><span style={{ fontWeight: 400 }}>D365Licenses.com retains all personal information collected on D365Licenses.com Internet Sites and web forms as long as necessary to provide the services, products, and information you request or as permitted by applicable law.</span></p>
                <p><span style={{ fontWeight: 400 }}>We respond to all requests we receive from individuals wishing to exercise their data protection rights in accordance with applicable data protection laws. Notwithstanding the foregoing, we reserve the right to keep any information in our archives that we deem necessary to comply with our legal obligations, resolve disputes and enforce our agreements.</span></p>
                <p><b>Access to Personal Information</b></p>
                <p><span style={{ fontWeight: 400 }}>You may review, correct, and update the personal information that you provide to us by writing to us at D365Licenses.com, 556 S. Fair Oaks Ave, #101-425, Pasadena, California, 91105 or emailing us at Info@D365Licenses.com.</span></p>

                <ol start={6}>
                    <li><b> HOW DO WE KEEP YOUR INFORMATION SAFE?</b></li>
                </ol>
                <p><b>In Short: </b><span style={{ fontWeight: 400 }}>We aim to protect your personal information through a system of organizational and technical security measures.</span></p>
                <p><span style={{ fontWeight: 400 }}>Your personal information will generally be stored in D365Licenses.com databases or databases maintained by our service providers. Most of these databases are stored on servers located in the United States. To the extent required by law, if your personal information will be transferred outside your country an appropriate notice will be provided. D365Licenses.com maintains reasonable safeguards to protect the confidentiality, security, and integrity of your personal information. For example, we use secure socket layer (SSL) technology to transfer personal information over the Internet. Although we use security measures to help protect your personal information against unauthorized disclosure, misuse or alteration, as is the case with all computer networks linked to the Internet, we cannot guarantee the security of information provided over the Internet and will not be responsible for breaches of security beyond our reasonable control.</span></p>

                <ol start={7}>
                    <li><b> WHAT ARE YOUR PRIVACY RIGHTS?</b></li>
                </ol>
                <p><b>In Short: </b><span style={{ fontWeight: 400 }}>In some regions, such as the European Economic Area, you have rights that allow you greater access to and control you’re your personal information. You may review, change, or terminate your account at any time.</span></p>
                <p><span style={{ fontWeight: 400 }}>If you are a resident of the European Economic Area, you have the following data protection rights:</span></p>

                <ul>
                    <li style={{ fontWeight: 400 }} aria-level={1}><span style={{ fontWeight: 400 }}>If you wish to access, correct, update or request deletion of your personal information, you can do so at any time by contacting us using the contact details provided under the Contact D365Licenses.com below.</span></li>
                    <li style={{ fontWeight: 400 }} aria-level={1}><span style={{ fontWeight: 400 }}>In addition, you can object to the processing of your personal information, ask us to restrict processing of your personal information or request portability of your personal information. Again, you can exercise these rights by contacting us using the contact details provided under Contact D365Licenses.com below.</span></li>
                    <li style={{ fontWeight: 400 }} aria-level={1}><span style={{ fontWeight: 400 }}>You have the right to opt-out of marketing communications we send you at any time. You can exercise this right by clicking on the "unsubscribe" or "opt-out" link in the marketing emails we send you. To opt-out of other forms of marketing (such as postal marketing or telemarketing), you may contact us using the contact details provided under Contact D365Licenses.com below.</span></li>
                    <li style={{ fontWeight: 400 }} aria-level={1}><span style={{ fontWeight: 400 }}>Similarly, if we have collected and processed your personal information with your consent, then you can withdraw your consent at any time. Withdrawing your consent will not affect the lawfulness of any processing we conducted prior to your withdrawal, nor will it affect processing of your personal information conducted in reliance on lawful processing grounds other than consent.</span></li>
                    <li style={{ fontWeight: 400 }} aria-level={1}><span style={{ fontWeight: 400 }}>You have the right to complain to a data protection authority about our collection and use of your personal information. For more information, please contact your local data protection authority.</span></li>
                </ul>
                <p><span style={{ fontWeight: 400 }}>Cookies and similar technologies: Most Web browsers are set to accept cookies by default. If you prefer, you can usually choose to set your browser to remove cookies and to reject cookies.</span></p>

                <ol start={8}>
                    <li><b> DO WE MAKE UPDATES TO THIS POLICY?</b></li>
                </ol>
                <p><b>In Short: </b><span style={{ fontWeight: 400 }}>Yes, we will make updates to this policy as necessary to stay compliant with relevant laws.</span></p>
                <p><span style={{ fontWeight: 400 }}>D365Licenses.com reserves the right, at its sole discretion, to change, modify, add or remove any portion of this policy, in whole or in part, at any time. Changes in Policy will be effective when posted. You agree to review this Policy periodically to be aware of any changes. Your continued use of the Site after any changes to this Policy will be considered acceptance of those changes.</span></p>

                <ol start={9}>
                    <li><b> CALIFORNIA CONSUMER PRIVACY ACT</b></li>
                </ol>
                <p><span style={{ fontWeight: 400 }}>If you are a California resident, you may have certain rights under the California Consumer Privacy Act regarding your personal information, including:</span></p>

                <ul>
                    <li style={{ fontWeight: 400 }} aria-level={1}><span style={{ fontWeight: 400 }}>the right to request the specific pieces of personal information we collected about you; the categories of personal information we collected; the categories of sources used to collect the personal information; the business or commercial purposes for collecting personal information.</span></li>
                    <li style={{ fontWeight: 400 }} aria-level={1}><span style={{ fontWeight: 400 }}>the right to request the categories of third parties with whom the personal information is are sold or transferred; categories of personal information that has been “disclosed for a business purpose” (that is, transferred but not a “sale”) and the categories of “third parties” to whom each category of personal information was transferred.</span></li>
                    <li style={{ fontWeight: 400 }} aria-level={1}><span style={{ fontWeight: 400 }}>the right to request, on legitimate grounds, deletion of your personal information that we collected.</span></li>
                    <li style={{ fontWeight: 400 }} aria-level={1}><span style={{ fontWeight: 400 }}>the right to have someone you authorize make a request on your behalf; and</span></li>
                    <li style={{ fontWeight: 400 }} aria-level={1}><span style={{ fontWeight: 400 }}>the right not to be discriminated against for exercising any of these rights.</span></li>
                </ul>
                <p><span style={{ fontWeight: 400 }}>You can exercise one or more of these rights by emailing us at Info@D365Licenses.com. You have the right to opt-out of marketing communications we send you at any time.&nbsp;</span></p>

                <ol start={10}>
                    <li><b> HOW CAN YOU CONTACT US ABOUT THIS POLICY?</b></li>
                </ol>
                <p><span style={{ fontWeight: 400 }}>If you have any questions about this Policy or our use of your personal information, you may email us at Info@D365Licenses.com or by post to: D365Licenses.com, 556 S. Fair Oaks Ave, #101-425, Pasadena, California 91105, USA</span></p>
                <p className="p1"></p></div>
            <br />
            <Footer routeOnClick={routeOnClick} />
        </>
    );
}