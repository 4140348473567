﻿import React from 'react';
import { RouteOnClick } from './app';

export function HeroHeader({ heroCategory, heroText }: { heroCategory: string; heroText: string }) {
    return (
        <div className="d-flex align-items-center" style={{ height: "200px", position: 'relative' }}>
            <div className="contact-hero" />
            <div className="contact-hero-tint" />
            <div className="w-100 center-text">
                <b style={{ color: '#edad2a', margin: 0 }}>{heroCategory}</b>
                <h1><b style={{ color: 'white' }}>{heroText}</b></h1>
            </div>
        </div>
    );
}

export function useOnChangeAnim(duration: number, dependencies: React.DependencyList) {
    const [playAnim, setPlayAnim] = React.useState(false);
    const [firstTime, setFirstTime] = React.useState(true);
    React.useEffect(() => {
        if (firstTime) {
            setFirstTime(false);
            return;
        }
        setPlayAnim(true);
        setTimeout(() => setPlayAnim(false), duration);
    }, dependencies);
    return playAnim;
}

export function useAnim(duration: number): [boolean, () => void] {
    const [playAnim, setPlayAnim] = React.useState(false);
    const runAnim = () => {
        setPlayAnim(true);
        setTimeout(() => setPlayAnim(false), duration);
    };
    return [playAnim, runAnim];
}

export const usePrevious = <T extends unknown>(value: T): T | undefined => {
    const ref = React.useRef<T>();
    React.useEffect(() => {
        ref.current = value;
    });
    return ref.current;
};

export function ShoppingCartIcon({ width, height }: { width: string;  height: string }) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} fillRule="inherit" className="bi bi-cart4" viewBox="0 0 16 16">
            <path d="M0 2.5A.5.5 0 0 1 .5 2H2a.5.5 0 0 1 .485.379L2.89 4H14.5a.5.5 0 0 1 .485.621l-1.5 6A.5.5 0 0 1 13 11H4a.5.5 0 0 1-.485-.379L1.61 3H.5a.5.5 0 0 1-.5-.5M3.14 5l.5 2H5V5zM6 5v2h2V5zm3 0v2h2V5zm3 0v2h1.36l.5-2zm1.11 3H12v2h.61zM11 8H9v2h2zM8 8H6v2h2zM5 8H3.89l.5 2H5zm0 5a1 1 0 1 0 0 2 1 1 0 0 0 0-2m-2 1a2 2 0 1 1 4 0 2 2 0 0 1-4 0m9-1a1 1 0 1 0 0 2 1 1 0 0 0 0-2m-2 1a2 2 0 1 1 4 0 2 2 0 0 1-4 0" />
        </svg>
    );
}

export function AnnouncementRibbon({ width, children }: React.PropsWithChildren<{width?: string}>) {
    return (
        <div className="py-2" style={{ backgroundColor: "#AED668" }}>
            <div className="center-element" style={{ width: (width ? width : "75%") }}>
                <p style={{ fontSize: "15pt" }} className="my-0 support-text-blue-dark center-text">{children}</p>
            </div>
        </div>
    )
}

export function EmailLink() {
    return (<a className="text-dark" href="mailto: info@d365licenses.com">info@d365licenses.com</a>);
}

export const isSandboxed = () => window.location.pathname.includes("/sandbox");

export interface IShoppingCartButton {
    productCount: number;
    shoppingEnabled?: boolean;
    routeOnClick: RouteOnClick;
}
export function ShoppingCartButton({ productCount, shoppingEnabled, routeOnClick }: IShoppingCartButton) {
    // TODO: Disable it or something when shopping not enabled
    return (
        <a type="button" className="btn btn-warning headerButton cartButton" style={{ position: "relative", zIndex: 1 }} href="/home/shop" onClick={(e) => {
            e.preventDefault();
            if (shoppingEnabled) {
                routeOnClick(e);
            }
        }}>
            <ShoppingCartIcon width="25" height="25" />
            &nbsp;
            <strong id="cartCount">Shopping Cart ({productCount})</strong>
        </a>
    );
}