﻿import React from 'react';
import { PageNav, RouteOnClick } from './app';
import { EmailLink } from './common';
export function Footer({ routeOnClick, hideContact }: { routeOnClick: RouteOnClick; hideContact?: boolean }) {
    return (
        <>
            { hideContact ? <></> : <ContactContainer /> } 
            <footer className="footer text-center text-lg-start">
                <div style={{ backgroundColor: "#42B8FD", height: "10px" }} />
                <div className="d-flex justify-content-between p-4 px-5 pb-5 pt-5 w-75 center-element">
                    <div style={{ width: "200px", maxWidth: "25%" }}>
                        <a onClick={routeOnClick} href="/"><img src="/images/LogoDarkBg.svg" className="w-100" alt="D365 Licenses Logo" /></a>
                        
                    </div>
                    <div className="flex-fill w-75 d-flex flex-column justify-content-center">
                        <p className="text-white center-text mb-0" style={{ fontSize: "12pt" }}>
                            <a className="text-white" href="/home" onClick={routeOnClick}>Home</a> | <a className="text-white" href="/home/service" onClick={routeOnClick}>Service</a> | <a className="text-white" href="/home/eula" onClick={routeOnClick}>EULA</a> | <a className="text-white" href="/home/privacy_policy" onClick={routeOnClick}>Privacy Policy</a>
                        </p>
                    </div>
                    <div className="d-flex flex-column justify-content-center" style={{ position: "relative", top: "-12px", width: "200px", maxWidth: "25%" }}>
                        {/* Top is half size of text */}
                        <p className="text-white m-0" style={{ textWrap: "nowrap" }}>- Powered by -</p>
                        <a href="https://cowrysolutions.com/"><img src="/images/cowrysolutionslogo.webp" className="w-100" alt="Cowry Solutions Logo" /></a>
                    </div>
                </div>
                <div style={{ backgroundColor: "#0A1B45" }}>
                    <p className="text-white mb-0">Copyright 2024 D365licenses.com | All Rights Reserved</p>
                </div>
            </footer>
        </>
    );
}

function ContactContainer() {
    return (
        <>
            <div className="center-element mt-5 d-flex justify-content-center">
                <div className="mb-5 mx-5">
                    <p className="display-2" style={{ fontWeight: "bold" }}>Get in touch</p>
                    <div className="d-flex justify-content-center">
                        <div className="me-3 pt-2 ps-2" style={{ backgroundImage: "url(/images/contact-hero2.webp)", backgroundSize: "cover", paddingRight: "4rem" }}>
                            <p><b>Contact us for any of these services through phone or email</b></p>
                        </div>
                        <div className="ms-3">
                            <p><b className="support-text-blue" style={{ fontSize: "18pt" }}>Main Office</b></p>
                            <p>Pasadena, CA</p>
                            <p><b className="support-text-blue" style={{ fontSize: "18pt" }}>Email Address</b></p>
                            <p><EmailLink /></p>
                            <p className="support-text-blue" style={{ fontSize: "18pt", fontWeight: "bold" }}>Phone Number</p>
                            <p><a className="text-dark" href="tel: +1 (213) 459-5858">+1 (213) 459-5858</a></p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}