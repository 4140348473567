﻿import React, { Key, useEffect, useMemo, useRef, useState } from "react"

type FieldDefinition = {
    name: string;
    //type: React.HTMLInputTypeAttribute;
    parse: (value: string) => FieldStateReturn<string>
}

type FormDefinition<T extends FormFieldDefinitions<T>> = {
    fields: FormFieldDefinitions<T>;
}

type FormFieldDefinitions<T> = {
    [Property in keyof T]: T[Property] extends FieldDefinition ? T[Property] : never
}

type FormFieldState = FieldState<string> & { fieldValue: string };

// TODO: some way for this to be type generic
type FormFieldStates<T extends FormFieldDefinitions<T>> = {
    [Property in keyof T]: FormFieldState;
}

type FieldRenders<T extends FormFieldDefinitions<T>> = {
    [Property in keyof T]: (value: string, prevError: string, disabled: boolean, setState: (newState: FormFieldState) => void) => React.JSX.Element;
}

type FormValues<T extends FormFieldDefinitions<T>> = {
    [Property in keyof T]: string
}

type FieldRefs<T extends FormFieldDefinitions<T>> = {
    [Property in keyof T]: React.RefObject<HTMLInputElement>;
}

type FormHook<T extends FormFieldDefinitions<T>> = {
    getValues: () => (FormValues<T> | null);
    render: () => React.JSX.Element;
}

export type Response<X, Y> = { success: true } & X | { success: false } & Y;

type SendStates = "none" | "sending" | "sent";
type Send<X, Y> = { state: "none" } | { state: "sending" } | { state: "sent", response: Response<X, Y> };

export function UseForm<
    T extends FormFieldDefinitions<T>,
    SuccessT,
    FailureT>
    (
        definitions: FormDefinition<T>,
        onSubmit: (values: FormValues<T>) => Promise<Response<SuccessT, FailureT>>,
        onSubmitSuccess: (successResponse: SuccessT) => void,
        onSubmitError: (failureResponse: FailureT) => void
    )
    : FormHook<T> {
    const initialFieldStates = useMemo(() => {
        console.log("build initial");
        const fieldStatesBuild: any = {};
        for (const key in definitions.fields) {
            const definition = definitions.fields[key];
            const state = definition.parse("");
            if (state.type == "revert") throw "Parser cannot revert on empty input";
            fieldStatesBuild[key] = { ...state, fieldValue: "" };
        }
        return fieldStatesBuild as FormFieldStates<T>;
    }, [definitions]);

    const renders = useMemo(() => {
        console.log("build renders");
        const rendersBuild: any = {};
        for (const key in definitions.fields) {
            const definition = definitions.fields[key];
            const render = (value: string, prevError: string, disabled: boolean, setState: (newState: FormFieldState) => void) => {
                return (<Input
                    name={definition.name}
                    key={key}
                    value={value}
                    disabled={disabled}
                    setValue={(value: string) => {
                        const newState = definition.parse(value);
                        if (newState.type == "revert") return prevError;
                        console.log(newState);
                        setState({ ...newState, fieldValue: value });
                        if (newState.type == "error") {
                            return newState.msg;
                        }
                        return "";
                    }} />);
            }
            rendersBuild[key] = render;
        }
        return rendersBuild as FieldRenders<T>;
    }, [definitions]);

    const [fieldStates, setFieldStates] = useState(initialFieldStates);
    const [sending, setSending] = useState<Send<SuccessT, FailureT>>({ state: "none" });

    const getValues = () => {
        const finalReturn: any = {};
        for (const key in definitions.fields) {
            const state = fieldStates[key];
            if (state.type == "error") {
                return null;
            } else {
                finalReturn[key] = state.value;
            }
        }
        return finalReturn as FormValues<T>;
    }

    useEffect(() => {
        switch (sending.state) {
            case "none":
                {
                    break;
                }
            case "sending":
                {
                    const values = getValues();
                    if (values) {
                        onSubmit(values).then(response => setSending({ state: "sent", response }));
                    } else {
                        setSending({ state: "none" });
                    }
                    break;
                }
            case "sent":
                {
                    if (sending.response.success) {
                        onSubmitSuccess(sending.response);
                    } else {
                        onSubmitError(sending.response);
                    }
                    setSending({ state: "none" });
                    break;
                }
        }
    }, [sending]);

    const renderInternal = useMemo(() => (
        fieldStates: FormFieldStates<T>,
        set: typeof setFieldStates,
        sending: boolean,
        send: () => void) => {
        const elems: React.JSX.Element[] = [];
        for (const key in fieldStates) {
            const state = fieldStates[key];
            elems.push(renders[key](state.fieldValue, state.type == "error" ? state.msg : "", sending,
                (newState) => {
                    set({ ...fieldStates, [key]: newState });
                }
            ));
        }
        return (
            <form onSubmit={async (e) => {
                e.preventDefault();
                send();
            }}>
                <div className="form-group">
                    {elems}
                    <br />
                    <button className="btn btn-primary" disabled={sending} type="submit">
                        {sending ?
                            (
                                <>
                                    <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                    <span className="sr-only"> Purchasing...</span>
                                </>
                            ) :
                            (
                                <span className="sr-only">Purchase</span>
                            )
                        }
                    </button>
                </div>
            </form>
        );
    }, [definitions]);

    const render = () => renderInternal(fieldStates, setFieldStates, sending.state != "none", () => setSending({ state: "sending" }));

    return { getValues, render };
}

interface IInput {
    name: string;
    value: string;
    disabled: boolean;
    setValue: (value: string) => string;
}
function Input({ name, value, disabled, setValue }: IInput) {
    const inputRef = useRef<HTMLInputElement>(null);
    return (
        <>
            <label htmlFor={name + "inputid"}>{name}:</label>
            <input id={name + "inputid"} placeholder={"Enter " + name} className="form-control" required disabled={disabled} ref={inputRef} value={value}
                onChange={(event) => {
                    inputRef.current?.setCustomValidity(setValue(event.target.value));
                }} />
        </>
    );
}

export type FieldValue<ValueType> = {
    type: "value",
    value: ValueType
}
export type FieldError = {
    type: "error",
    msg: string
}
export type FieldRevert = {
    type: "revert"
}
export type FieldState<ValueType> = FieldValue<ValueType> | FieldError;
export type FieldStateReturn<ValueType> = FieldState<ValueType> | FieldRevert;
export function fieldValue<ValueType>(value: ValueType): FieldStateReturn<ValueType> {
    return { type: "value", value };
}
export function error(msg: string): FieldError {
    return { type: "error", msg };
}
export function revert(): FieldRevert {
    return { type: "revert" };
}