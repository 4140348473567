﻿import React, { useEffect, useMemo, useRef, useState } from 'react';
import { HeaderNav } from "../headernav";
import { GetProductOptionValue, LoadCart, OptionState, Product, ProductOption, ProductState, ProductStates, ResetProductState, SetStorageProductState, ZipProductsWithState, ZippedProductsOptions, getProductCount } from "../products";
import { IPage } from '../app';
import { UseForm, error, Response } from './form';
import { chainParser, conditionalParse, idParse, mapParser, maxSize, needsValue, onlyInteger } from './field_parsers';
import { Overlay, OverlayCloseButton, useMessageOverlay, useOverlay } from '../react_util';
import { LicenseHaveValues, ShoppingCart, ShoppingCartAndTotal, TenantHaveValues, TotalDisplay } from './cart';
import { formatWithDiscount, neverCheck, round, sleep } from '../util';
import { Checkout, CheckoutValues, SendResult } from './checkout';
import { Footer } from '../footer';
import { EmailLink, ShoppingCartIcon, isSandboxed } from '../common';
import Decimal from 'decimal.js';

declare const handleAdditionalDiscount: boolean;

const creditCardParser = chainParser(needsValue<string>, onlyInteger);
const expiryDateParser =
    chainParser(needsValue<string>,
        chainParser(onlyInteger, maxSize(2)));

const expiryYearParser = expiryDateParser;
const expiryMonthParser =
    mapParser(
        chainParser(
            mapParser(expiryDateParser, x => +x),
            conditionalParse(month => 1 <= month && month <= 12, error("Month must be between 1 and 12")))
        , x => x.toString());
const securityCodeParser =
    chainParser(needsValue<string>,
        chainParser(onlyInteger, maxSize(4)));

type PurchaseOrder = CheckoutValues & {
    productList: { id: string; quantity: number }[];
    additionalDiscount: number;
    haveLicense: LicenseHaveValues;
    haveTenant: TenantHaveValues;
}

export interface ICartCheckout extends IPage {
    products: Product[];
}
export function CartCheckout(props: ICartCheckout) {
    const [cartState, setCartState] = useState(LoadCart(props.products, props.version));
    const [additionalDiscount, setAdditionalDiscount] = useState(new Decimal(0));
    const [haveLicense, setHaveLicense] = useState<LicenseHaveValues | undefined>(undefined);
    const [haveTenant, setHaveTenant] = useState<TenantHaveValues | undefined>(undefined);
    async function sendPurchase(fields: CheckoutValues): Promise<SendResult> {
        const fieldsWithAmount: PurchaseOrder = {
            ...fields,
            additionalDiscount: additionalDiscount.toNumber(),
            productList:
                ZippedProductsOptions(ZipProductsWithState(props.products, cartState))
                    .filter(p => p.quantity > 0)
                    .map(p => ({ id: p.dataverseId, quantity: p.quantity })),
            haveLicense: haveLicense ?? LicenseHaveValues.No,
            haveTenant: haveTenant ?? TenantHaveValues.DontKnow,
        };
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(fieldsWithAmount)
        };
        let failureMessage = "";
        try {
            const response = await fetch(isSandboxed() ? '/sandbox/request/buy' : '/request/buy', requestOptions);
            if (response.status == 400) {
                failureMessage = await response.text();
            } else {
                window.scrollTo(0, 0);
                return { type: "success" };
            }
        } catch (ex) {
            console.log(ex);
            failureMessage = "Transaction failed: " + ex.message;
        }
        return { type: "error", error: failureMessage };
    }
    

    const or = (pv: boolean, cv: boolean) => pv || cv;
    const hasAnyProducts = cartState.array.map(ps => ps.options.map(po => po.quantity > 0).reduce(or, false)).reduce(or, false);

    const [pageState, setPageState] = useState<"cart" | "checkout" | "success">("cart");
    const PageToDisplay = (() => {
        switch (pageState) {
            case "cart":
                return (
                    (hasAnyProducts ?
                        <div className="mx-5">
                            <ShoppingCartAndTotal
                                products={props.products}
                                cartState={cartState}
                                globalDiscount={props.globalDiscount}
                                updateProduct={(productName, optionName, quantity) => {
                                    cartState.Set(productName, optionName, quantity);
                                    setCartState(cartState.copy());
                                    SetStorageProductState("ProductStates", cartState);
                                }}
                                proceedToCheckout={() => setPageState("checkout")}
                                haveLicense={haveLicense}
                                setHaveLicense={setHaveLicense}
                                haveTenant={haveTenant}
                                setHaveTenant={setHaveTenant}
                            />
                        </div>
                        :
                        <div className="mx-5">
                            <hr />
                            <div className="d-flex justify-content-between">
                                <p className="text-muted mb-0"> Your cart is currently empty </p>
                                <div>
                                    <a className="mb-0" href="/" onClick={props.routeOnClick} style={{ textDecoration: "none", color: "rgb(245, 128, 37)" }}>
                                        <ShoppingCartIcon width="21" height="21" />&nbsp;
                                        Return to shop
                                    </a>
                                </div>
                            </div>
                            <hr />
                        </div>
                    )
                );
            case "checkout":
                return (
                    <>
                        <div className="ms-5">
                            <button className="btn btn-secondary" onClick={() => setPageState("cart")}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-return-left" viewBox="0 0 16 16">
                                    <path fill-rule="evenodd" d="M14.5 1.5a.5.5 0 0 1 .5.5v4.8a2.5 2.5 0 0 1-2.5 2.5H2.707l3.347 3.346a.5.5 0 0 1-.708.708l-4.2-4.2a.5.5 0 0 1 0-.708l4-4a.5.5 0 1 1 .708.708L2.707 8.3H12.5A1.5 1.5 0 0 0 14 6.8V2a.5.5 0 0 1 .5-.5" />
                                </svg>
                                &nbsp;
                                Go back to Edit Shopping Cart
                            </button>
                        </div>
                        <br />
                        <div className="d-flex flex-row justify-content-center flex-wrap">
                            <div className="mx-3">
                                <ShoppingCart
                                    products={props.products}
                                    cartState={cartState}
                                    globalDiscount={props.globalDiscount}
                                    disableQuantityChange
                                    updateProduct={(productName, optionName, quantity) => {
                                        cartState.Set(productName, optionName, quantity);
                                        setCartState(cartState.copy());
                                    }}
                                />
                            </div>
                            {/*<div className="vr mx-4"></div>*/}
                            <div className="mx-3">
                                <SummaryDisplay
                                    cart={cartState}
                                    products={props.products}
                                    discount={props.globalDiscount}
                                    additionalDiscount={additionalDiscount}
                                    updateAdditionalDiscount={(discount) => setAdditionalDiscount(discount)}
                                />
                            </div>
                        </div>
                        <br />
                        <hr className="center-element w-75" />
                        <br />
                        <div className="d-flex flex-row justify-content-center">
                            <div style={{ minWidth: "50%" }}>
                                <Checkout sendPurchase={sendPurchase} setSuccess={() => {
                                    setPageState("success");
                                    ResetProductState("ProductStatesCart");
                                    ResetProductState("ProductStates");
                                }} />
                            </div>
                        </div>
                    </>
                );
            case "success":
                return (
                    <div className="center-text mx-5">
                        <h2 className="fw-bold">Purchase complete</h2>
                        <p className="h3">Thank you for purchasing licenses from d365licenses.com. It can take up to 24 business hours for your licenses to be available for use.</p>
                        <p className="h3">Please click <a className="text-dark" onClick={props.routeOnClick} href="/home/instructional">here</a> for instructions on next steps. If you have any questions please reach out to <EmailLink /></p>
                        <svg xmlns="http://www.w3.org/2000/svg" width="10%" fill="currentColor" className="center-element bi bi-check" viewBox="0 0 16 16">
                            <path d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425z" />
                        </svg>
                    </div>
                );
            default:
                return neverCheck(pageState);
        }
    })();
    return (
        <div>
            <HeaderNav routeOnClick={props.routeOnClick} cartState={cartState} shoppingEnabled={false} />
            <br />
            <StepsHeader />
            <br />
            <br />
            <div style={{ marginBottom: "200px" }}>
                { PageToDisplay }
            </div>
            <Footer routeOnClick={props.routeOnClick}/>
        </div>
    );
}

function SummaryDisplay({
    cart,
    products,
    discount,
    additionalDiscount,
    updateAdditionalDiscount
}: {
        cart: ProductStates;
        products: Product[];
        discount: Decimal;
        additionalDiscount: Decimal;
        updateAdditionalDiscount: (discount: Decimal) => void;
    }) {
    const subtotal = cart.Total(products, 1).mul(12);
    return (
        <>
            <tbody className="mx-3">
                <tr>
                    <td className="pe-3"><h5 className="text-start fw-bold">Order Summary</h5></td>
                    <td><h5 className="text-end"></h5></td>
                </tr>
                <tr>
                    <td><hr className="tableSeparator thickBorder" /></td>
                    <td><hr className="tableSeparator thickBorder" /></td>
                </tr>
                <tr>
                    <td className="ps-2 pe-3"><h5 className="text-start"><ShoppingCartIcon width="16px" height="16px" /> {getProductCount(cart)} Item(s) in Cart</h5></td>
                    <td className="pe-2"><h5 className="text-end"></h5></td>
                </tr>
                <tr>
                    <td><hr className="tableSeparator" /></td>
                    <td><hr className="tableSeparator" /></td>
                </tr>

                <SummaryProductsMSRP classNames={["ps-2 pe-2", "pe-2"]} products={products} cart={cart} />

                <tr>
                    <td className="ps-2 pe-3"><h5 className="text-start">D365Licenses.com Discount</h5></td>
                    <td className="pe-2"><h5 className="text-end text-success">-${subtotal.mul(discount.mul(-1).add(1)).toFixed(2)}</h5></td>
                </tr>
                {
                    handleAdditionalDiscount ?
                        <tr>
                            <td className="ps-2 me-3"><h5 className="text-start">Additional Discount</h5></td>
                            <td className="pe-2">
                                <AdditionalDiscountInput updateAdditionalDiscount={updateAdditionalDiscount} />
                            </td>
                        </tr> :
                        <tr>
                            <td className="ps-2 me-3"><h5 className="text-start">Discount code</h5></td>
                            <td className="pe-2">
                                <h5 className="text-end text-success">
                                    <input disabled style={{ textAlign: "end" }} type="text"/>
                                </h5>
                            </td>
                        </tr>
                }
                <tr>
                    <td className="ps-2 me-3"><h5 className="text-start fw-bold">Total</h5></td>
                    <td className="pe-2"><h5 className="text-end fw-bold">${subtotal.mul(discount).sub(additionalDiscount).toFixed(2)}</h5></td>
                </tr>
                <tr>
                    <td><hr className="tableSeparator" /></td>
                    <td><hr className="tableSeparator" /></td>
                </tr>
            </tbody>
            <p className="center-text h5 fw-bold">Total Savings ${subtotal.mul(discount.mul(-1).add(1)).toFixed(2)}</p>
        </>
    );
}

export function SummaryProductsMSRP({ classNames, products, cart }: { classNames?: [string, string]; products: Product[]; cart: ProductStates }) {
    return (
        <>
            {
                ZippedProductsOptions(ZipProductsWithState(products, cart))
                    .filter(p => p.quantity > 0)
                    .map(p =>
                        <SummaryProductMSRP
                            classNames={classNames}
                            key={p.skewNumber}
                            skewNumber={p.skewNumber}
                            crayonSkew={p.crayonSkew}
                            msftPrice={p.microsoftPrice}
                            quantity={p.quantity}
                        />)
            }
        </>
    )
}

function SummaryProductMSRP(
    {
        classNames,
        skewNumber,
        crayonSkew,
        msftPrice,
        quantity }: {
            classNames?: [string, string];
            skewNumber: string;
            crayonSkew: string;
            msftPrice: Decimal;
            quantity: number;
        }) {
    return (
        <tr>
            <td className={classNames?.[0] ?? ""}>
                <p className="text-start h5">
                    {quantity}x {skewNumber}&nbsp;
                    <sup className="text-muted" style={{ fontSize: "9pt" }}>
                        {crayonSkew}
                    </sup>
                </p>
            </td>
            <td className={classNames?.[1] ?? ""}><p className="text-end h5">${msftPrice.mul(quantity).mul(12).toFixed(2)}</p></td>
        </tr>
    );
}

function AdditionalDiscountInput({ updateAdditionalDiscount }: { updateAdditionalDiscount: (discount: Decimal) => void; }) {
    const [errorStatus, setErrorStatus] = useState(false);
    const [display, setDisplay] = useState("0.00");
    const errorStatusCss = errorStatus ? "input-text-error" : ""
    return (
        <h5 className="text-end text-success">
            -$<input
                className={errorStatusCss}
                style={{ textAlign: "end" }}
                type="text"
                value={display}
                onChange={(ev) => {
                    setDisplay(ev.currentTarget.value);
                    try {
                        const targetValue = new Decimal(ev.currentTarget.value === "" ? 0 : ev.currentTarget.value)
                        updateAdditionalDiscount(targetValue);
                        return;
                    } catch {
                        updateAdditionalDiscount(new Decimal(0));
                        setErrorStatus(true);
                    }
                }}
            />
        </h5>
    );
}

function StepsHeader() {
    return (
        <div className="d-flex justify-content-center">
            <StepsBlock number={1} color="#DD6060" title="You Are Here">Select and purchase desired licenses with a great discount</StepsBlock>
            <StepsBlock number={2} color="#039FA4" title="Within 24 Business Hours">Receive your license receipt and installation instructions</StepsBlock>
            <StepsBlock number={3} color="#FFAD0D" hideArrow title="Optional">Contact us with any questions, issues, or additional services</StepsBlock>
        </div>
    );
}

function StepsBlock({ number, color, hideArrow, title, children }: React.PropsWithChildren<{ number: number; color: string; hideArrow?: boolean; title: string; }>) {
    const withArrowCss = (hideArrow) ? "" : "steps-block-with-arrow";
    return (
        <div className={`steps-block d-flex flex-column ${withArrowCss}`}>
            <div className="d-flex justify-content-center" style={{ position: "relative", top: "30px" }}>
                <p className="m-0 text-white border rounded-circle center-text fw-bold"
                    style={{ width: "70px", height: "70px", backgroundColor: color, fontSize: "35pt" }}>
                    {number}
                </p>
            </div>
            <div className="steps-text-block flex-fill d-flex flex-column" style={{ backgroundColor: "#EBEFFE" }}>
                <div className="steps-title-block m-0 mt-1 d-flex flex-column justify-content-center">
                    <p className="steps-title-text">{title}</p>
                    {/*hideArrow ? <></> : <img src="/images/StepsArrow.svg" className="steps-arrow" /> */}
                    {hideArrow ? <></> :
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            className="bi bi-chevron-right"
                            viewBox="0 0 16 16"
                            style={{
                                    position: 'absolute',
                                    transform: "translate(227px, 1px) scale(2)",
                                    zIndex: 2,
                                }}
                        >
                            <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708"/>
                        </svg> }
                </div>
                <p className="flex-fill m-0 mt-2">{children}</p>
            </div>
        </div>
    );
}