﻿import React, { useState } from 'react';
import { isInteger } from './checkout/field_parsers';
export interface IQuantity {
    quantity: number;
    minimumPurchase: number | undefined;
    setQuantity: (n: number) => void;
}
export function QuantityCell(props: IQuantity) {
    const setQuantity = (delta: number) => {
        let value = Math.max(0, props.quantity + delta);
        if (value != 0 && props.minimumPurchase && value < props.minimumPurchase) {
            if (props.quantity === 0) {
                value = props.minimumPurchase;
            } else {
                value = 0;
            }
        }
        props.setQuantity(value);
        setDisplayValue(value.toString());
    }
    const [displayValue, setDisplayValue] = useState(props.quantity.toString());
    // Kind of hacky, but I can't seem to find a way to do it otherwise
    // This exists to fix the issue that when the selection option is changed, it does not take the correct value
    // from the quantity, and keeps using the same display value.
    // TODO: Fix it properly, no useEffect
    React.useEffect(() => {
        setDisplayValue(props.quantity.toString());
    }, [props.quantity]);
    return (
        <td className='quantityCell' style={{ color: 'white' }}>
            <div className='quantityContainer'>
                <div className="quantityBackground">
                    <button className='quantityButtonDash' onClick={() => setQuantity(-1)}>
                        {props.quantity === props.minimumPurchase ? <b style={{ fontSize: "14pt", position: "relative", "top": "-11px", "right": "2px" }}>🗑️</b> : <b>-</b>}
                    </button>
                    <div className="quantityTitle d-flex flex-column justify-content-center">
                        <input type="text" value={displayValue} className="center-text" style={{ border: "none" }} maxLength={2}
                            onChange={(ev) => {
                                setDisplayValue(ev.currentTarget.value);
                                if (isInteger(ev.currentTarget.value)) {
                                    props.setQuantity(+ev.currentTarget.value);
                                }
                            }}
                            onBlur={() => {
                                setDisplayValue(props.quantity.toString());
                            }}
                        />
                    </div>
                    <button className='quantityButtonPlus' onClick={() => setQuantity(1)}>
                        <b className="">+</b>
                    </button>
                </div>
            </div>
            {
                props.minimumPurchase && props.minimumPurchase > 1 ?
                    <div className="d-flex justify-content-center">
                        <p className="m-0 text-center text-secondary" style={{ fontSize: "10pt", position: "absolute" }}>
                            Minimum purchase of {props.minimumPurchase}
                        </p>
                    </div> :
                    <></>
            }
        </td>
    );
}