﻿import React, { useEffect, useMemo, useRef, useState } from 'react';
import { GetProductOptionValue, OptionState, Product, ProductOption, ProductState, ProductStates, ResetProductState, ZipProductsWithState, ZippedProductsOptions } from "../products";
import { SetFunc, formatWithDiscount, round } from '../util';
import { QuantityCell } from '../cart_components';
import Decimal from 'decimal.js';
import { SummaryProductsMSRP } from '.';

export type IShoppingCartAndTotal = IShoppingCart & {
    proceedToCheckout: () => void;
    haveLicense: LicenseHaveValues | undefined;
    setHaveLicense: SetFunc<LicenseHaveValues | undefined>;
    haveTenant: TenantHaveValues | undefined;
    setHaveTenant: SetFunc<TenantHaveValues | undefined>;
}
export function ShoppingCartAndTotal(
    {
        products,
        cartState,
        globalDiscount,
        updateProduct,
        proceedToCheckout,
        haveLicense,
        setHaveLicense,
        haveTenant,
        setHaveTenant
    }: IShoppingCartAndTotal) {
    return (
        <div className="d-flex flex-wrap justify-content-center">
            <div className="mx-3 flex-fill">
                <ShoppingCart products={products} cartState={cartState} globalDiscount={globalDiscount} updateProduct={updateProduct} />
            </div>
            <div className="d-flex flex-column mx-3">
                <TotalDisplay
                    products={products}
                    cart={cartState}
                    discount={globalDiscount}
                    haveLicense={haveLicense}
                    setHaveLicense={setHaveLicense}
                    haveTenant={haveTenant}
                    setHaveTenant={setHaveTenant}
                />
                <button
                    className="btn btn-primary"
                    disabled={haveLicense === undefined || haveTenant === undefined}
                    onClick={proceedToCheckout}>
                    Proceed to Checkout
                </button>
                <p><span className="text-danger">*</span><span className="text-secondary">Required</span></p>
            </div>
        </div>
    );
}

export type IShoppingCart = {
    products: Product[];
    globalDiscount: Decimal;
    cartState: ProductStates;
    disableQuantityChange?: boolean;
    updateProduct: (productName: string, optionName: string, quantity: number) => void;
}

export function ShoppingCart({ products, cartState, globalDiscount, disableQuantityChange, updateProduct }: IShoppingCart) {
    const zipped = ZippedProductsOptions(ZipProductsWithState(products, cartState));
    return (
        <>
            {zipped
                .filter(product => product.quantity > 0)
                .map(product =>
                    <ProductRow
                        key={product.productName + product.name}
                        name={product.productName}
                        type={product.name}
                        image={product.imageLink}
                        price={product.microsoftPrice.mul(globalDiscount)}
                        quantity={product.quantity}
                        minimumPurchase={product.minimumPurchase}
                        disableQuantityChange={disableQuantityChange}
                        setQuantity={(q: number) => updateProduct(product.productName, product.name, q)}
                    />
                )}
        </>
    );
}

interface IProductRow {
    name: string;
    type: string;
    image: string;
    price: Decimal;
    quantity: number;
    minimumPurchase: number | undefined;
    disableQuantityChange: boolean | undefined;
    setQuantity: (q: number) => void;
}
function ProductRow({ name, type, image, price, quantity, minimumPurchase, disableQuantityChange, setQuantity }: IProductRow) {
    return (
        <div className="shadow p-3 mb-5 bg-white rounded">
            <table className="p-3 w-100">
                <ProductRowHeader />
                <tbody>
                    <tr>
                        <td>
                            <div className="d-flex">
                                <img style={{ maxWidth: "75px" }} src={image}></img>
                                <div className="d-flex flex-column justify-content-center">
                                    <b className="ms-2">{name}</b>
                                </div>
                            </div>
                        </td>
                        <td className="center-text">{type}</td>
                        <td className="center-text">${price.toFixed(2)} Monthly</td>
                        <td>
                            {disableQuantityChange ?
                                <div className="d-flex flex-column justify-content-center">
                                    <span className="center-text">
                                        {quantity}
                                    </span>
                                </div> : 
                                <div className="d-flex justify-content-center">
                                    <QuantityCell quantity={quantity} minimumPurchase={minimumPurchase} setQuantity={setQuantity} />
                                </div>
                            }
                        </td>
                        <td className="center-text"><b>${price.mul(quantity).toFixed(2)} Monthly</b></td>
                    </tr>
                </tbody>
            </table>
        </div>
    );
}

function ProductRowHeader() {
    return (
        <thead>
            <tr>
                <th className="w-25">Product</th>
                <th className="w-20 center-text">Type</th>
                <th className="w-10 center-text">Price</th>
                <th className="w-35 px-5 center-text">Quantity</th>
                <th className="w-10 center-text">Total</th>
            </tr>
        </thead>
    );
}

// Horrible amounts of error prone boilerplate
export enum LicenseHaveValues {
    No = 0,
    Yes = 1,
}
const allLicenseHaveValues = [LicenseHaveValues.No, LicenseHaveValues.Yes];
function getHaveLicenseName(value: LicenseHaveValues) {
    switch (value) {
        case LicenseHaveValues.No:
            return "No";
        case LicenseHaveValues.Yes:
            return "Yes";
    }
}

export enum TenantHaveValues {
    No = 0,
    Yes = 1,
    DontKnow = 2,
}
const allTenantHaveValues = [TenantHaveValues.DontKnow, TenantHaveValues.No, TenantHaveValues.Yes];
function getHaveTenantName(value: TenantHaveValues) {
    switch (value) {
        case TenantHaveValues.No:
            return "No";
        case TenantHaveValues.Yes:
            return "Yes";
        case TenantHaveValues.DontKnow:
            return "I don't know";
    }
}

interface ITotalDisplay {
    products: Product[];
    cart: ProductStates;
    discount: Decimal;
    haveLicense: LicenseHaveValues | undefined;
    setHaveLicense: SetFunc<LicenseHaveValues | undefined>;
    haveTenant: TenantHaveValues | undefined;
    setHaveTenant: SetFunc<TenantHaveValues | undefined>;
}
export function TotalDisplay({ products, cart, discount, haveLicense, setHaveLicense, haveTenant, setHaveTenant }: ITotalDisplay) {
    const subtotal = cart.Total(products, 1).mul(12);
    return (
        <table className="w-100">
            <tbody>
                <tr>
                    <td><label htmlFor="d365licensehaveselect" className="text-start h5">Do you currently have D365?</label><span className="text-danger me-5">*</span></td>
                    <td>
                        <select
                            className="h5 float-right"
                            id="d365licensehaveselect"
                            onChange={(ev) => setHaveLicense(ev.currentTarget.value === "" ? undefined : +ev.currentTarget.value)}
                        >
                            {
                                [(<option key={-1} selected={undefined === haveLicense} value={undefined} />)]
                                .concat(
                                    allLicenseHaveValues.map(v => <option key={v} selected={v === haveLicense} value={v}>{getHaveLicenseName(v)}</option>)
                                )
                            }
                        </select>
                    </td>
                </tr>
                <tr>
                    <td><label htmlFor="tenanthaveselect" className="text-start h5">Do you currently have a Microsoft Tenant?</label><span className="text-danger me-5">*</span></td>
                    <td>
                        <select
                            className="h5 float-right"
                            id="tenanthaveselect"
                            onChange={(ev) => setHaveTenant(ev.currentTarget.value === "" ? undefined : +ev.currentTarget.value)}
                        >
                            {
                                [(<option key={-1} selected={undefined === haveTenant} value={undefined} />)]
                                .concat(
                                    allTenantHaveValues.map(v => <option key={v} selected={v === haveTenant} value={v}>{getHaveTenantName(v)}</option>)
                                )
                            }
                        </select>
                    </td>
                </tr>
                <tr>
                    <td><h5 className="text-start me-5">Total Microsoft List Price</h5></td>
                    <td><h5 className="text-end">${subtotal.toFixed(2)}</h5></td>
                </tr>
                <SummaryProductsMSRP products={products} cart={cart} />
                <tr>
                    <td><h5 className="text-start">D365Licenses.com Savings</h5></td>
                    <td><h5 className="text-end">-${subtotal.mul(discount.mul(-1).add(1)).toFixed(2)}</h5></td>
                </tr>
                <tr>
                    <td><h5 className="text-start">Total</h5></td>
                    <td><h5 className="text-end">${subtotal.mul(discount).toFixed(2)}</h5></td>
                </tr>
            </tbody>
        </table>
    );
}