﻿import React, { isValidElement, useEffect, useRef, useState } from 'react';
import { FieldStateReturn, error, fieldValue } from './form';
import { chainParser, conditionalParse, contains, mapParser, maxSize, needsValue, onlyInteger } from './field_parsers';
import { range } from '../util';

export interface ICheckout {
    sendPurchase: (fields: CheckoutValues) => Promise<SendResult>
    setSuccess: () => void;
}

export type SendResult = { type: "success" } | { type: "error", error: string };
export type CheckoutValues = { [T in Fields]: string };

type Fields = "nameOnCard" | "cardNumber" | "expiryMonth" | "expiryYear" | "ccv" | "email" | "companyName";

export function Checkout({ sendPurchase, setSuccess }: ICheckout) {
    let currentYear = new Date().getFullYear();
    const isDecember = new Date().getMonth() === 11
    currentYear = isDecember ? currentYear + 1 : currentYear;
    const currentYearLastTwo = (new Date().getFullYear() % 100).toString();

    const [sendState, setSendState] = useState<"none" | "start_send" | "sending" | "success">("none");
    const [sendError, setSendError] = useState<string | null>(null);
    const disabled = sendState === "start_send" || sendState === "sending" || sendState === "success";
    const init = () => ({ value: "", prevError: "" });
    const [fieldValues, setFieldValues] = useState<{ [T in Fields]: { value: string; prevError: string; } }>({
        nameOnCard: init(),
        cardNumber: init(),
        expiryMonth: { value: (new Date().getMonth() + 2).toString().padStart(2, "0"), prevError: "" },
        expiryYear: { value: currentYearLastTwo, prevError: "" },
        ccv: init(),
        email: init(),
        companyName: init(),
    });
    const setFieldValue = (field: keyof typeof fieldValues, parse: (value: string) => FieldStateReturn<string>) => (value: string) => {
        const state = fieldValues[field];
        const newState = parse(value);
        if (newState.type === "revert") return state.prevError;
        state.value = value;
        if (newState.type === "error") {
            state.prevError = newState.msg;
            setFieldValues({ ...fieldValues });
            return newState.msg;
        } else {
            setFieldValues({ ...fieldValues });
            return "";
        }
    };
    useEffect(() => {
        switch (sendState) {
            case "none":
                break;
            case "start_send":
                setSendState("sending");
                setSendError(null);
                const values = {
                    nameOnCard: fieldValues.nameOnCard.value,
                    cardNumber: fieldValues.cardNumber.value,
                    expiryMonth: fieldValues.expiryMonth.value,
                    expiryYear: fieldValues.expiryYear.value,
                    ccv: fieldValues.ccv.value,
                    email: fieldValues.email.value,
                    companyName: fieldValues.companyName.value,
                }
                sendPurchase(values).then(result => {
                    if (result.type === "error") {
                        setSendError(result.error);
                        setSendState("none");
                    } else {
                        setSendError(null);
                        setSendState("success");
                    }
                });
                break;
            case "sending":
                break;
            case "success":
                setSuccess();
                break;
        }
    }, [sendState])
    return (
        <form onSubmit={async (e) => {
            e.preventDefault();
            if (sendState === "none") {
                setSendState("start_send");
            }
        }}>
            {
                sendError === null ?
                    (<></>) :
                    (<div className="alert alert-danger" role="alert">
                        There was an error processing the transaction: {sendError}
                    </div>)
            }
            <label htmlFor={"Emailinputid"}><b>Email Address</b></label>
            <Input name="Email" value={fieldValues.email.value} placeholder="Email Address" disabled={disabled} className="" setValue={setFieldValue("email", emailParser)} />
            <br />
            <label htmlFor={"Name on cardinputid"}><b>Name on card</b></label>
            <Input name="Name on card" value={fieldValues.nameOnCard.value} placeholder="Name on card" disabled={disabled} className="" setValue={setFieldValue("nameOnCard", needsValue)} />
            <br />
            <label htmlFor={"Company Nameinputid"}><b>Company Name</b></label>
            <Input name="Company Name" value={fieldValues.companyName.value} placeholder="Company Name" disabled={disabled} className="" setValue={setFieldValue("companyName", needsValue)} />
            <br />
            <label htmlFor={"Card Numberinputid"}><b>Card Number</b></label>
            <Input name="Card Number" value={fieldValues.cardNumber.value} placeholder="1111-2222-3333-4444" disabled={disabled} className="" setValue={setFieldValue("cardNumber", creditCardParser)} />
            <br />
            <b>Expires on:</b>
            <div className="d-flex flex-row">
                <div className="me-3 d-flex">
                    <label htmlFor="Year" className="me-2"><b className="vertical-align-sub center-text">Year</b></label>
                    <select className="form-select" name="Year" disabled={disabled} onChange={(event) => {
                        fieldValues.expiryYear.value = event.target.value;
                        setFieldValues({ ...fieldValues });
                    }}>
                        {range(currentYear, Math.floor(currentYear / 100) * 100 + 99).map(i => <option key={i} value={(i % 100).toString()}>{i}</option>)}
                    </select>
                </div>
                <div className="d-flex">
                    <label htmlFor="Month" className="me-2"><b className="vertical-align-sub center-text">Month</b></label>
                    <select className="form-select" name="Month" disabled={disabled} onChange={(event) => {
                        fieldValues.expiryMonth.value = event.target.value;
                        setFieldValues({ ...fieldValues });
                    }}>
                        {
                            (currentYearLastTwo === fieldValues.expiryYear.value && !isDecember ? range(new Date().getMonth() + 2, 12) : range(1, 12))
                                .map(i => <option key={i} value={i >= 10 ? i : "0" + i}>{i}</option>)
                        }
                    </select>
                </div>
            </div>
            <br />
            <div className="d-flex flex-row">
                <label htmlFor="CCVinputid"><b className="vertical-align-sub center-text">CCV&nbsp;&nbsp;&nbsp;</b></label>
                <Input name="CCV" type="password" value={fieldValues.ccv.value} placeholder="415" disabled={disabled} className="w-25" setValue={setFieldValue("ccv", securityCodeParser)} />
            </div>
            <br />
            <div className="w-50 center-element">
                <button className="btn btn-primary w-100" disabled={disabled} type="submit">
                    {sendState === "sending" ?
                        (<div className="d-flex flex-row justify-content-center">
                            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        </div>) :
                        (<span className="sr-only">Purchase</span>)
                    }
                </button>
            </div>
        </form>);
}

interface IInput {
    name: string;
    placeholder: string;
    value: string;
    disabled: boolean;
    className: string;
    type?: React.HTMLInputTypeAttribute;
    setValue: (value: string) => string;
}
function Input({ name, placeholder, value, disabled, className, type, setValue }: IInput) {
    const inputRef = useRef<HTMLInputElement>(null);
    return (
        <>
            <input type={type ? type : "text"} id={name + "inputid"} placeholder={placeholder} className={"form-control " + className} required disabled={disabled} ref={inputRef} value={value}
                onChange={(event) => {
                    inputRef.current?.setCustomValidity(setValue(event.target.value));
                }} />
        </>
    );
}

const creditCardParser =
    chainParser(needsValue<string>,
        chainParser(maxSize(20), onlyInteger));

const expiryDateParser =
    chainParser(needsValue<string>,
        chainParser(onlyInteger, maxSize(2)));

const expiryYearParser = expiryDateParser;
const expiryMonthParser =
    mapParser(
        chainParser(
            mapParser(expiryDateParser, x => +x),
            conditionalParse(month => 1 <= month && month <= 12, error("Month must be between 1 and 12")))
        , x => x.toString());
const securityCodeParser =
    chainParser(needsValue<string>,
        chainParser(onlyInteger, maxSize(4)))

const emailParser =
    chainParser(needsValue<string>,
        contains("@"));