﻿import ReactDOM from 'react-dom/client';
import React, { useEffect } from 'react';
import { Shop } from './Shop.tsx';
import { LoadCart, Product, ProductFloat, ProductState, ProductStates, ProductsFloatToProducts } from './products.ts';
import { CartCheckout } from './checkout/';
import { PrivacyPolicy } from './privacy_policy.tsx';
import Decimal from 'decimal.js';
import { Eula } from './eula.tsx';
import { Service } from './service.tsx';
import { Instructional } from './instructional.tsx';

export type PageNav = {
    navigateToShop: () => void;
    navigateToCheckout: () => boolean;
    navigateToPrivacyPolicy: () => void;
    navigateToEula: () => void;
    navigateToService: () => void;
}
export type Route = (route: string) => void;
export type RouteOnClick = (event: React.MouseEvent<HTMLAnchorElement | HTMLButtonElement>) => void;
export interface IPage {
    route: Route;
    routeOnClick: RouteOnClick;
    products: Product[];
    globalDiscount: Decimal;
    version: number;
}

type Routes = {
    [key: string]: (props: IPage) => React.JSX.Element;
}

// TODO: Have an actual 404
const route404 = (props: IPage) => <Shop {...props} />;

const isSandboxed = window.location.pathname.includes("/sandbox");

function adjustRoute(route: string) {
    if (route.endsWith("/")) {
        route = route.substring(0, route.length - 1);
    }
    if (route.length === 0) {
        route = isSandboxed ? "" : "/";
    }
    return (isSandboxed ? "/sandbox" : "") + route.toLowerCase();
}

const routes: Routes = {
    [adjustRoute("/")]: (props: IPage) => <Shop {...props} />,
    [adjustRoute("/home")]: (props: IPage) => <Shop {...props} />,
    [adjustRoute("/home/shop")]: (props: IPage) => <CartCheckout {...props} />,
    [adjustRoute("/home/privacy_policy")]: (props: IPage) => <PrivacyPolicy {...props} />,
    [adjustRoute("/home/eula")]: (props: IPage) => <Eula {...props} />,
    [adjustRoute("/home/service")]: (props: IPage) => <Service {...props} />,
    [adjustRoute("/home/instructional")]: (props: IPage) => <Instructional {...props} />
}

function getRoute() {
    let location = window.location.pathname;
    return location;
}

function App({ initialRoute, products, globalDiscount, version }: { initialRoute: string; products: Product[], globalDiscount: Decimal, version: number }) {
    const [currentRoute, setCurrentRoute] = React.useState(initialRoute);
    const route = (route: string) => {
        window.history.pushState({}, "", adjustRoute(route));
        window.scrollTo(0, 0);
        setCurrentRoute(getRoute());
    }
    const routeOnClick = (event: React.MouseEvent<HTMLAnchorElement | HTMLButtonElement>) => {
        event.preventDefault();
        route((event.currentTarget as any).pathname);
    };
    useEffect(() => {
        const popstateListener = () => {
            setCurrentRoute(getRoute());
        };
        window.addEventListener("popstate", popstateListener);
        return () => {
            window.removeEventListener("popstate", popstateListener);
        };
    }, []);
    const newRoute = routes[currentRoute] ?? route404;
    const props = { route, routeOnClick, products, globalDiscount, version };
    return newRoute(props);
}

declare const allProducts: ProductFloat[];
declare const globalDiscount: number;
declare const version: number;

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(<App products={ProductsFloatToProducts(allProducts)} globalDiscount={new Decimal(globalDiscount)} version={version} initialRoute={getRoute()} />);