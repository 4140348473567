﻿import React from 'react';
import { IPage } from './app';
import { LoadCart } from './products';
import { HeaderNav } from './headernav';
import { Footer } from './footer';
import { AnnouncementRibbon, HeroHeader } from './common';

export function Service({ routeOnClick, products, version }: IPage) {
    const cartState = LoadCart(products, version);
    const blockCount = 5;
    const width = 100 / blockCount;
    return (
        <>
            <HeaderNav routeOnClick={routeOnClick} cartState={cartState} shoppingEnabled />
            <HeroHeader heroCategory="Support" heroText="Services" />
            <div className="background-blue">
                <br />
                <div className="w-75 center-element">
                    <br />
                    <h2 className="h1 support-text-white" style={{ fontSize: "45pt" }}><b>Need more than just licenses?</b></h2>
                    <br />
                    <p><h3 className="h2 support-text-white"><b>Here's what we do and how we can help:</b></h3></p>
                    <br />
                    <br />
                </div>
                <AnnouncementRibbon>
                    All of our license sales come with free support for installation, however we don't just stop there.<br /><b>We are available at affordable hourly or monthly rates to keep you running smoothly!</b>
                </AnnouncementRibbon>
                <br />
                <br />
                <div className="w-75 center-element d-flex justify-content-evenly">
                    <ServiceBlock width={width} title="Troubleshooting" subtitle="We figure it out" image="/images/troubleshooting_icon.svg" colorRgba={[0,255,255]} />
                    <ServiceBlock width={width} title="Setup & Configuration" subtitle="We get you going" image="/images/setup_configuration_icon.svg" colorRgba={[255, 135, 170]} />
                    <ServiceBlock width={width} title="Optimization" subtitle="We make it make sense" image="/images/optimization_icon.svg" colorRgba={[255, 128, 0]} />
                    <ServiceBlock width={width} title="Customizations" subtitle="We make it yours" image="/images/customization_icon.svg" colorRgba={[144, 73, 200]} />
                    <ServiceBlock width={width} title="Support & Training" subtitle="We are there for you" image="/images/support_training_icon.svg" colorRgba={[0, 255, 0]} />
                </div>
                <br />
                <br />
                <br />
            </div>
            <br />
            <br />
            <Footer routeOnClick={routeOnClick} />
        </>
    );
}

function ServiceBlock(
    { width, title, subtitle, image, colorRgba }: {
        width: number;
        title: string;
        subtitle: string;
        image: string;
        colorRgba: [number, number, number]
    }) {
    const genColorText = (alpha: number) => `rgba(${colorRgba[0]},${colorRgba[1]},${colorRgba[2]},${alpha})`;
    return (
        <div className="hide-overflow mx-4" style={{ width: width + "%", height: "350px" }}>
            <div className="p-3">
                <img src={image} className="w-100" style={{
                    padding: "2rem",
                    backgroundImage: `linear-gradient(${genColorText(0)},${genColorText(0.6)})`
                }} />
            </div>
            
            <h4 className="h3 support-text-blue center-text" style={{ fontSize: "16.5pt" }}><b>{title}</b></h4>
            <p className="support-text-white center-text">{subtitle}</p>
        </div>);
}