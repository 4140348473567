﻿import { FieldError, FieldRevert, FieldStateReturn, error, fieldValue, revert } from "./form";

export type Parser<I, O> = (value: I) => FieldStateReturn<O>;
export function mapParser<I, O1, O2>(parser: Parser<I, O1>, f: (value: O1) => O2): Parser<I, O2> {
    return (value) => {
        const result = parser(value);
        if (result.type === "value") {
            return fieldValue(f(result.value));
        } else {
            return result;
        }
    }
}
export function chainParser<I, O1, O2>(parser1: Parser<I, O1>, parser2: Parser<O1, O2>): Parser<I, O2> {
    return (value) => {
        const result = parser1(value);
        if (result.type === "value") {
            return parser2(result.value);
        } else {
            return result;
        }
    }
}
export function conditionalParse<I>(condition: (value: I) => boolean, ifFalse: FieldError | FieldRevert): Parser<I, I> {
    return (value) => {
        if (condition(value)) {
            return fieldValue(value);
        } else {
            return ifFalse;
        }
    }
}
export function idParse(value: string): FieldStateReturn<string> {
    return { type: "value", value };
}
export function needsValue<T>(value: T) {
    return conditionalParse<T>(value => !(value == undefined || value == ""), error("Please fill out this field."))(value);
}
export function alwaysError(value: string, msg: string): FieldStateReturn<string> {
    return { type: "error", msg };
}
export function isInteger(value: string) {
    let containsLetter = false;
    for (const c of value) {
        if (!(c >= '0' && c <= '9')) {
            containsLetter = true;
        }
    }
    return !containsLetter;
}
export function onlyInteger(value: string) {
    return conditionalParse(isInteger, revert())(value);
}
export function maxSize(max: number) {
    return conditionalParse<string>(value => value.length <= max, revert());
}
export function contains(value: string) {
    return conditionalParse((i: string) => i.includes(value), error("Must input a valid email address"));
}
