﻿import { formatWithDiscount, sumf } from "./util";
import Decimal from 'decimal.js';

const copy = (x: any) => x.copy();
export class OptionState {
    name: string;
    quantity: number;
    constructor(name: string, quantity: number) {
        this.name = name;
        this.quantity = quantity;
    }
    copy() {
        return new OptionState(this.name, this.quantity);
    }
}

type ProductOptionBase<T> = {
    optionName: string;
    microsoftPrice: T;
    minimumPurchase: number | undefined;
    dataverseId: string;
    requirements: string;
    skewNumber: string;
    crayonSkew: string;
}
type ProductBase<T> = {
    name: string;
    learnMoreLink: string;
    imageLink: string;
    productOptions: ProductOptionBase<T>[];
    color: string;
}
export type ProductOptionFloat = ProductOptionBase<number>;
export type ProductFloat = ProductBase<number>;
export type ProductOption = ProductOptionBase<Decimal>;
export type Product = ProductBase<Decimal>;

export function ProductsFloatToProducts(productsIn: ProductFloat[]): Product[] {
    return productsIn
        .map(p => ({
            ...p,
            productOptions:
                p.productOptions
                    .map(po => ({ ...po, microsoftPrice: new Decimal(po.microsoftPrice) }))
        }));
}

export type OptionWithState = {
    name: string;
    quantity: number;
    minimumPurchase: number | undefined;
    microsoftPrice: Decimal;
    dataverseId: string;
    skewNumber: string;
    crayonSkew: string;
}

export type ProductWithState = {
    name: string;
    imageLink: string;
    options: OptionWithState[];
}

export function ZipProductsWithState(products: Product[], states: ProductStates): ProductWithState[] {
    return products.map(product => {
        const state = states.Get(product.name);
        const options = product.productOptions.map(productOption => {
            const stateOption = state.option(productOption.optionName)!;
            return {
                minimumPurchase: productOption.minimumPurchase,
                name: productOption.optionName,
                quantity: stateOption.quantity,
                microsoftPrice: productOption.microsoftPrice,
                dataverseId: productOption.dataverseId,
                skewNumber: productOption.skewNumber,
                crayonSkew: productOption.crayonSkew,
            };
        });
        return { name: product.name, imageLink: product.imageLink, options };
    });
}

export function ZippedProductsOptions(products: ProductWithState[]): (OptionWithState & { productName: string, imageLink: string })[] {
    return products.map(product => product.options.map(x => ({ ...x, productName: product.name, imageLink: product.imageLink }) )).flat();
}
export function GetProductOption(product: Product, optionName: string) {
    return product.productOptions.find(pp => pp.optionName == optionName) as ProductOption;
}
export function GetProductOptionValue(products: Product[], productName: string, optionName: string) {
    return ((products.find(p => p.name == productName) as Product).productOptions.find(pp => pp.optionName == optionName) as ProductOption).microsoftPrice
}
type ProductStatesKey = "ProductStatesCart" | "ProductStates";
export class ProductStates {
    version: number;
    key: ProductStatesKey;
    array: ProductState[];
    constructor(localStorageKey: ProductStatesKey, productList: Product[] | undefined, version: number, skipLoadArray: ProductState[] | undefined) {

        const generateNew: (productList: Product[]) => void = ((productList: Product[]) => {
            console.log(localStorageKey, "No stored product states found, creating new ones.");
            this.array = productList.map((product) => {
                return new ProductState(
                    product.name,
                    product.productOptions.map(o => new OptionState(o.optionName, 0)),
                    undefined
                );
            });
            this.version = version;
        }).bind(this);

        this.key = localStorageKey;
        if (skipLoadArray) {
            console.log(localStorageKey, "Skipped loading from localstorage");
            this.array = skipLoadArray;
            this.version = version;
            return;
        }
        if (productList == undefined || productList.length == 0) {
            console.log(localStorageKey, "No product list provided, clearing product states.")
            this.array = [];
            this.version = version;
            return;
        }
        const stored = localStorage.getItem(localStorageKey);
        if (stored == null) {
            generateNew(productList);
        } else {
            console.log(localStorageKey, "Found stored product states, loading them.");
            const obj = JSON.parse(stored) as ProductStates;
            if (version !== obj.version) {
                generateNew(productList);
            } else {
                const state = obj.array.map((productState: ProductState) => {
                    return new ProductState(productState.name, productState.options.map(os => new OptionState(os.name, os.quantity)), productState.activeOption);
                });
                this.array = state;
                this.version = obj.version;
            }
        }
    }
    copy() {
        return new ProductStates(this.key, undefined, this.version, this.array.map(copy));
    }
    CloneFrom(from: ProductStates) {
        return new ProductStates(this.key, undefined, this.version, from.array.map(copy));
    }
    GetProductStateHandle(name: string): ProductHandle {
        for (let i = 0; i < this.array.length; i++) {
            if (this.array[i].name == name) {
                return new ProductHandle(this, i);
            }
        }
        throw "Couldn't find product named " + name;
    }
    Get(name: string): ProductState {
        for (let i = 0; i < this.array.length; i++) {
            if (this.array[i].name == name) {
                return this.array[i];
            }
        }
        throw "Couldn't find product named " + name;
    }
    Set(productName: string, optionName: string, quantity: number) {
        for (let i = 0; i < this.array.length; i++) {
            if (this.array[i].name == productName) {
                const product = this.array[i];
                for (let j = 0; j < this.array.length; j++) {
                    if (product.options[j].name == optionName) {
                        const option = product.options[j];
                        option.quantity = quantity;
                        this.UpdateLocalStorage();
                        return;
                    }
                }
                return;
            }
        }
    }
    UpdateLocalStorage() {
        localStorage.setItem(this.key, JSON.stringify(this));
    }
    HasAnyProducts() {
        const reduceOr = (acc: boolean, b: boolean) => acc || b;
        return this.array.map(product => product.options.map(option => option.quantity > 0).reduce(reduceOr, false)).reduce(reduceOr, false);
    }
    Total(products: Product[], globalDiscount: Decimal | 1) {
        return this.array
            .map(product => product.options
                .map(o => GetProductOptionValue(products, product.name, o.name).mul(o.quantity).mul(globalDiscount))
                .reduce((acc, n) => acc.add(n), new Decimal(0)))
            .reduce((acc, n) => acc.add(n), new Decimal(0));
    }
}
export function SetStorageProductState(key: ProductStatesKey, state: ProductStates) {
    localStorage.setItem(key, JSON.stringify(state));
}
export function ResetProductState(localStorageKey: ProductStatesKey) {
    localStorage.removeItem(localStorageKey);
}

export class ProductState {
    // Options: OptionState[]
    name: string;
    options: OptionState[];
    activeOption: string | undefined;
    constructor(name: string, options: OptionState[], activeOption: string | undefined) {
        this.name = name;
        this.options = options;
        this.activeOption = activeOption;
    }
    option(name: string | undefined) {
        for (let i = 0; i < this.options.length; i++) {
            if (this.options[i].name === name) {
                return this.options[i];
            }
        }
        return undefined;
        //throw "Could not find option " + name + " for " + this.name;
    }
    getActiveOption() {
        return this.option(this.activeOption);
    }
    copy() {
        return new ProductState(this.name, this.options.map(copy), this.activeOption);
    }
}
export class ProductHandle {
    arrayHandle: ProductStates;
    arrayIndex: number;
    constructor(array: ProductStates, index: number) {
        this.arrayHandle = array;
        this.arrayIndex = index;
    }
    UpdateProduct(productState: ProductState) {
        this.arrayHandle.array[this.arrayIndex] = productState;
        this.arrayHandle.UpdateLocalStorage();
    }
    get Product() {
        return this.arrayHandle.array[this.arrayIndex];
    }
}

export function ProductGetActiveOption(product: Product, productState: ProductState) {
    const activeOptionName = productState.getActiveOption()?.name;
    return product.productOptions.find(p => p.optionName === activeOptionName);
}

export function LoadCart(products: Product[], version: number) {
    return new ProductStates("ProductStatesCart", products, version, undefined);
}

export function getProductCount(cartState: ProductStates) {
    return cartState.array
        .reduce(
            sumf(
                (productState: ProductState) => productState.options.reduce(
                    sumf(x => x.quantity)
                    , 0))
            , 0);
}