﻿export function round(value: number, digits: number): number {
    return +value.toFixed(digits);
}

export function withDiscount(originalPrice: number, discount: number): number {
    return ((100 - discount) / 100) * originalPrice;
}
export function formatWithDiscount(originalPrice: number, discount: number): number {
    return round(withDiscount(originalPrice, discount), 2);
}

export function sleep(ms: number): Promise<void> {
    return new Promise((resolve) => {
        setTimeout(() => resolve(), ms);
    });
}

export function unfold<T>(f: (index: number) => T | null) {
    const arr: T[] = [];
    for (let i = 0; ; i++) {
        const val = f(i);
        if (val) {
            arr.push(val);
        } else {
            return arr;
        }
    }
} 

export function range(from: number, to: number) {
    return unfold(i => {
        const index = i + from;
        if (index > to) return null;
        else return index;
    });
}

export function sumf<T>(f: (x: T) => number): (a: number, x: T) => number {
    return (a, x) => (a + f(x));
}

export function identity<T>(x: T): T {
    return x;
}

export function neverCheck(x: never): never { return x }

export type SetFunc<T> = (value: T) => void;