﻿import React, { useEffect, useState } from 'react';
import { Product, ProductState, ProductStates, ProductOption, ProductHandle, OptionState, ProductGetActiveOption, LoadCart, getProductCount, GetProductOption } from './products';
import { HeaderNav } from './headernav';
import { IPage, RouteOnClick } from './app';
import { QuantityCell } from './cart_components';
import { Footer } from './footer';
import Decimal from 'decimal.js';
import { EmailLink, ShoppingCartButton, useAnim, useOnChangeAnim, usePrevious } from './common';


//const allProducts = @Html.Raw(JsonSerializer.Serialize(Model.Products));
//const globalDiscount = @Model.GlobalDiscount;

export interface IShop extends IPage {
}
export function Shop(props: IShop) {
    // TODO: Something causes it to reload from localstorage when updated, figure out why?
    const [cartState, setCartState] = React.useState(LoadCart(props.products, props.version));
    const total = cartState.Total(props.products, props.globalDiscount);
    const totalmsft = cartState.Total(props.products, new Decimal(1.0));
    const search = useState("");
    React.useEffect(() => {
        cartState.UpdateLocalStorage();
    }, [cartState]);
    const rowRefs: { [key: string]: React.RefObject<HTMLTableRowElement> } = {};
    for (const product of props.products) {
        rowRefs[product.name] = React.useRef(null);
    }
    return (
        <>
            <HeaderNav routeOnClick={props.routeOnClick} cartState={cartState} shoppingEnabled>
                <SearchInput search={search} />
            </HeaderNav>
            <div style={{ background: "#001a45" }}>
            </div>
            <AnnouncingProducts products={props.products} rowRefs={rowRefs} />
            <Jumbotron total={total} totalmsft={totalmsft} />
            <div className='licenses' style={{ display: 'flex', flexDirection: 'row', flexWrap: 'nowrap', alignItems: 'flex-start', justifyContent: 'center' }}>
                <LicensesTable
                    products={props.products}
                    globalDiscount={props.globalDiscount}
                    cartStates={cartState}
                    version={props.version}
                    search={search}
                    routeOnClick={props.routeOnClick}
                    rowRefs={rowRefs}
                    updateCart={(newProductState) => {
                        setCartState(cartState.CloneFrom(newProductState));
                    }} />
            </div>
            <AdditionalInfo />
            <Footer routeOnClick={props.routeOnClick} hideContact />
        </>
    );
}
function AnnouncingProducts({ products, rowRefs }: { products: Product[], rowRefs: { [key: string]: React.RefObject<HTMLTableRowElement> } }) {
    return (
        <div className="announcingContainer my-3">
            <h2 className="announcingTitle support-text-blue m-0">Discounted Dynamics 365 Licenses</h2>
            <div className="productCardsList">
                {products.map((product: Product, index: number) => (
                    <div
                        key={index}
                        className={"productCard productCard" + product.name.replace(' ', '')}
                        style={{ outlineColor: product.color }}
                        onClick={() => rowRefs[product.name]?.current?.scrollIntoView({ behavior: 'smooth', block: 'center' })}
                    >
                        <div className="productCardImage">
                            <img src={product.imageLink} alt={product.name + " Logo"} />
                        </div>
                        <div className="productCardTitle">
                            <h6>{product.name}</h6>
                        </div>
                    </div>
                ))}
                {/*<div className="productCard allD365" style={{ outlineColor: 'rgb(100 115 227)' }}>
                    <div className="productCardImage allD365Image">
                        <img src={'https://logolook.net/wp-content/uploads/2022/10/Dynamics-365-Emblem.png'} alt={'D365 Logo'} />
                    </div>
                    <div className="productCardTitle allD365Title">
                        <h6>{'All D365 Products'}</h6>
                    </div>
                </div>*/}
            </div>
        </div>
    );
}
function Jumbotron({ totalmsft, total }: { totalmsft: Decimal; total: Decimal }) {
    const [playAnim, runAnim] = useAnim(500);
    const prevTotal = usePrevious(total);
    useEffect(() => {
        if (prevTotal && total.greaterThan(prevTotal)) runAnim();
    }, [total, prevTotal]);
    return (
        <div className="jumbotron">
            <div className="contact-hero" />
            <div className="contact-hero-tint" />
            <div className="mt-5 d-flex justify-content-between">
                <div className="mt-5 flex-fill">
                    <h1 className="display-4 jumbotronTitle center-text">All Dynamics D365 Products Available Now</h1>
                </div>
                <div className="text-white p-4" style={{ backgroundColor: "rgb(0, 26, 69)", borderRadius: "1rem" }}>
                    <div className="d-flex justify-content-between">
                        <p className="h4 me-3" style={{ color: "lightgray" }}> Total List Price: </p>
                        <p className="h4 text-end" style={{ color: "lightgray" }}>${totalmsft.toFixed(2)} Monthly</p>
                    </div>
                    <div className="d-flex justify-content-between">
                        {playAnim ?
                            <>
                                <p className="h4 anim-green-glow fw-bold me-3">Savings:</p>
                                <p className="h4 anim-green-glow fw-bold text-end">${(totalmsft.sub(total)).toFixed(2)} Monthly</p>
                            </>
                            :
                            <>
                                <p className="h4 fw-bold me-3">Savings:</p>
                                <p className="h4 fw-bold text-end">${(totalmsft.sub(total)).toFixed(2)} Monthly</p>
                            </>
                        } 
                    </div>
                    <div className="d-flex justify-content-between">
                        <p className="h4 me-3">Our Total:</p>
                        <p className="h4 text-end">${total.toFixed(2)} Monthly</p>
                    </div>
                </div>
            </div>

            {/*<div className="jumbotronLearnMoreContainer">
                <p className="jumbotronLearnMoreTitle">How we are able to offer discounts that other partners (and even Microsoft) can't offer</p>
                <a className="btn btn-primary btn-lg jumbotronLearnMoreLink" href="/" role="button">Learn More</a>
            </div>*/}
        </div>
        
    );
}

interface ILicensesTable {
    products: Product[];
    globalDiscount: Decimal;
    cartStates: ProductStates;
    version: number;
    search: [string, (newValue: string) => void];
    routeOnClick: RouteOnClick;
    rowRefs: { [key: string]: React.RefObject<HTMLTableRowElement> };
    updateCart: (newProductState: ProductStates) => void;
}
function LicensesTable(props: ILicensesTable) {
    return (
        <div className='tableContainer'>
            {
                typeof (props.globalDiscount) == 'number' && props.globalDiscount > 0 ?
                    <h6 className='specialOffer'>Special Offer - Over {props.globalDiscount}% Savings!</h6> :
                    <br></br>
            }

            <div className="d-flex justify-content-between">
                <SearchInput search={props.search} />
                <ShoppingCartButton
                    shoppingEnabled
                    productCount={getProductCount(props.cartStates)}
                    routeOnClick={props.routeOnClick}
                />
            </div>

            <table className='licenseTable mt-4'>
                <thead>
                    <tr>
                        <LicenseHeaderCell text="Microsoft Dynamics 365 Products" />
                        <LicenseHeaderCell text="Type" />
                        <LicenseHeaderCell text="Microsoft List Price" thStyle={{ width: "18rem" }} h3Style={{ maxWidth: '100rem' }} />
                        <LicenseHeaderCell text="Our Price" thStyle={{ width: '18rem' }} />
                        <LicenseHeaderCell text="Quantity" thStyle={{ width: "170px" }} />
                    </tr>
                </thead>
                <tbody>
                    {props.products
                        .filter(product =>
                            product.name.toLowerCase().includes(props.search[0]) ||
                            product.productOptions.filter(option => option.optionName.toLowerCase().includes(props.search[0])).length > 0)
                        .map(product => (
                            <LicenseRow
                                product={product}
                                discount={props.globalDiscount}
                                key={product.name}
                                productHandle={props.cartStates.GetProductStateHandle(product.name)}
                                cartState={props.cartStates.GetProductStateHandle(product.name).Product}
                                rowRef={props.rowRefs[product.name]}
                                updateCart={(forOption, quantity) => {
                                    const statesCopy = props.cartStates.copy();
                                    const newProductState = statesCopy.GetProductStateHandle(product.name).Product;
                                    newProductState.option(forOption)!.quantity = quantity;
                                    console.log("Cart update for", product.name, forOption, quantity);
                                    props.updateCart(statesCopy);
                                }}
                            />
                    ))}
                </tbody>
            </table>
            
            <div className="mt-3 d-flex justify-content-between">
                <p className="text-secondary">*Monthly price based on an annual commitment per license.</p>
                <ShoppingCartButton
                    shoppingEnabled
                    productCount={getProductCount(props.cartStates)}
                    routeOnClick={props.routeOnClick}
                />
            </div>
        </div>
    );
}

function LicenseHeaderCell(
    {
        text,
        thStyle,
        h3Style,
    }: {
            text: string;
            thStyle?: React.CSSProperties;
            h3Style?: React.CSSProperties;
    }) {
    return (
        <th className='table-cell' style={{ ...thStyle, verticalAlign: 'bottom' }}>
            <div>
                <div className="titleColumnContainer">
                    <h3 className='titleColumn' style={h3Style}>{text}</h3>
                </div>
            </div>
        </th>
    );
}

interface ILicenseRow {
    product: Product;
    discount: Decimal;
    productHandle: ProductHandle;
    cartState: ProductState;
    rowRef: React.RefObject<HTMLTableRowElement>;
    updateCart: (forOption: string, quantity: number) => void;
}
function LicenseRow(props: ILicenseRow) {
    const [productState, setProductState] = React.useState<ProductState>(props.productHandle.Product);
    React.useEffect(() => {
        props.productHandle.UpdateProduct(productState);
    }, [props.product, productState]);
    const activeOption = productState.getActiveOption();
    const activeOptionProductOptionDetails = props.product.productOptions.find(p => p.optionName === productState.activeOption);
    return (
        <>
            {
                props.product.productOptions.length > 0 ?
                    <tr
                        ref={props.rowRef}
                        className='licenseRow'
                        style={{ height: "120px" }} // horrible hack but i have no clue how to do it otherwise, i give up
                    >
                        <ProductCell product={props.product} />
                        <OptionCell product={props.product} activeOption={productState.activeOption} setActiveProduct={(o) => {
                            productState.activeOption = o?.optionName;
                            setProductState(productState.copy());
                        }} />

                        {(activeOptionProductOptionDetails) ?
                            <MicrosoftPriceCell
                                product={props.product}
                                price={activeOptionProductOptionDetails.microsoftPrice}
                                requirements={GetProductOption(props.product, productState.activeOption!).requirements}
                            /> :
                            <td></td>
                        }

                        <PerUserPriceCell product={props.product} discount={props.discount} price={activeOptionProductOptionDetails?.microsoftPrice} />

                        {(activeOption) ?
                            <QuantityCell
                                quantity={activeOption.quantity}
                                minimumPurchase={ProductGetActiveOption(props.product, productState)?.minimumPurchase}
                                setQuantity={(n) => {
                                    activeOption.quantity = n;
                                    setProductState(productState.copy());
                                    props.updateCart(activeOption.name, activeOption.quantity);
                                }} /> :
                            <td></td>
                        }
                    </tr> :
                    <tr ref={props.rowRef}>
                        <ProductCell product={props.product} />
                        <td><p className="h5">Coming soon</p></td>
                    </tr>
            }
            <tr>
                <td><hr className="tableSeparator" /></td>
                <td><hr className="tableSeparator" /></td>
                <td><hr className="tableSeparator" /></td>
                <td><hr className="tableSeparator" /></td>
                <td><hr className="tableSeparator" /></td>
                <td><hr className="tableSeparator" /></td>
            </tr>
        </>
    );
}
function ProductCell({ product }: { product: Product }) {
    return (
        <td>
            <div className='productContainer' key={product.name}>
                <div className="imageContainer">
                    <div className="border rounded-circle d-flex justify-content-center" style={{ backgroundColor: 'white', width: "70px", height: "70px" }}>
                        <img className='center-element productImage rounded-circle' src={product.imageLink} alt={product.name + " Logo"} />
                    </div>
                </div>
                <div className="nameAndLinkContainer">
                    <h4 className='productName'>{product.name}</h4>
                    <div className="linkContainer">
                        <a style={{ color: "#004A98" }} target='_blank' href={product.learnMoreLink}>Learn more</a>
                        <svg style={{ cursor: 'pointer' }} onClick={() => {
                            const url = product.learnMoreLink;
                            window.open(url, '_blank');
                        }} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#011A45" className="bi bi-arrow-right-short" viewBox="0 0 16 16">
                            <path fill-rule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8" />
                        </svg>
                    </div>
                </div>
            </div>
        </td>
    );
}
interface IOptionCell {
    product: Product;
    activeOption: string | undefined;
    setActiveProduct: (o: ProductOption | undefined) => void;
}
function OptionCell(props: IOptionCell) {
    return (
        <td>
            <select className='productOptions center-text' id={'selectOption' + props.product.name} onChange={() => {
                const select = document.getElementById('selectOption' + props.product.name) as HTMLInputElement;
                props.setActiveProduct(props.product.productOptions[+select.value]);
            }}>
                <option value={undefined} key={undefined} label="Select an option" selected={props.activeOption === undefined}>Select an option</option>
                {props.product.productOptions.map((productOption, i) => {
                    if (productOption.optionName === props.activeOption) {
                        return (
                            <option value={i} key={i} label={productOption.optionName} selected>{productOption.optionName}</option>
                        );

                    } else {
                        return (
                            <option value={i} key={i} label={productOption.optionName}>{productOption.optionName}</option>
                        );
                    }
                })}
            </select>
        </td>
    );
}
function MicrosoftPriceCell({ product, price, requirements }: { product: Product, price: Decimal, requirements: string }) {
    return (
        <td className='microsoftPriceCell' style={{ color: 'white' }}>
            <div className='priceContainer' key={product.name}>
                <span className="h4 priceTitle flex-fill center-text" style={{ display: "block" }}>
                    ${price.toFixed(2) + ' '}
                    Monthly
                    &nbsp;
                    <span className="shop-tooltip">
                        <b className="h4">&#x1F6C8;</b>
                        <span style={{ whiteSpace: "pre-wrap" }} className="shop-tooltiptext">
                            <div className="p-3">
                                {requirements}
                            </div>
                        </span>
                    </span>
                </span>
                <a target="_blank" href="https://dynamics.microsoft.com/en-us/pricing/" className="center-text" style={{ display: "block", color: "#004A98" }}> Microsoft MSRP Pricing </a>
            </div>
        </td>
    );
}
function getDiscount(originalPrice: number, discount: number) {
    return new Intl.NumberFormat(
        'en-US',
        {
            maximumFractionDigits: 0
        }
    ).format(
        ((100 - discount) / 100) * originalPrice
    );
}
interface IPerUserPriceCell {
    product: Product;
    discount: Decimal;
    price: Decimal | undefined;
}
function PerUserPriceCell(props: IPerUserPriceCell) {
    const playAnim = useOnChangeAnim(500, [props.price]);
    const cssClass = "priceContainer " + ((playAnim) ? "anim-grow-and-shrink" : "");
    return (
        (props.price) ?
            <td className='perUserPriceCell' style={{ color: 'white' }}>
                <div className={cssClass} key={props.product.name}>
                    <h4 className="perUserPriceTitle center-text">
                        ${props.price.mul(props.discount).toFixed(2) + ' '}Monthly*
                    </h4>
                    {
                        props.discount.greaterThan(0) ?
                            <p className='perUserPriceDisccount center-text'>Over {new Decimal(100).sub(props.discount.mul(100)).toFixed(0)}% Discount!</p> :
                            <p></p>
                    }
                </div>
            </td> :
            <td></td>
    );
}
interface IAddToCartButton {
    state: { active: OptionState, cart: OptionState } | undefined
    commitToCart: () => void;
}
function AddToCartButton(props: IAddToCartButton) {
    return (
        <td>
            {(props.state) ?
                <button
                    style={{ cursor: 'pointer' }}
                    className={
                        (props.state.cart == null || props.state.cart.quantity == 0) ?
                            "addToCartButton" :
                            (props.state.cart.quantity > 0 && props.state.active.quantity === 0) ?
                                "removeToCartButton" :
                                (props.state.cart.quantity !== props.state.active.quantity) ?
                                    "updateToCartButton" :
                                    "addedToCartButton"
                    } onClick={(e) => {
                        e.persist();
                        if (props.state === undefined) return;
                        if ((props.state.cart == null || props.state.cart.quantity == 0) && props.state.active.quantity !== 0) {
                            e.currentTarget.classList.replace(e.currentTarget.classList.value, 'addedToCartButton');
                            props.commitToCart();
                        }
                        else if (!(props.state.cart == null || props.state.cart.quantity == 0) && props.state.active.quantity === 0) {
                            e.currentTarget.classList.replace(e.currentTarget.classList.value, 'addToCartButton');
                            props.commitToCart();
                        }
                        else if (!(props.state.cart == null || props.state.cart.quantity == 0) && props.state.cart.quantity !== props.state.active.quantity) {
                            e.currentTarget.classList.replace(e.currentTarget.classList.value, 'addedToCartButton');
                            props.commitToCart();
                        }
                    }}>
                    {
                        (props.state.cart == null || props.state.cart.quantity == 0) ?
                            "Add To Cart" :
                            (!(props.state.cart == null || props.state.cart.quantity == 0) && props.state.active.quantity === 0) ?
                                "Remove Product" :
                                (props.state.cart.quantity !== props.state.active.quantity) ?
                                    "Update Quantity" :
                                    "Added To Cart"
                    }
                </button> :
                <button disabled style={{ cursor: 'pointer' }} className="addToCartButton">Add To Cart</button>
                }
            
        </td>
    );
}

function AdditionalInfo() {
    return (
        <>
            <br />
            <br />
            <br />
            <div className="hero container-fluid">
            </div>
            <div className="container rounded w-80 p-2">
                <div className="row justify-content-evenly">
                    <div className="col-md-4 my-5 pt-3 ps-5 d-flex flex-column justify-content-between">
                        <div>
                            <h3 style={{ color: "#0fb0cd" }} >Purchase Dynamics D365 Licenses</h3>
                        </div>
                        <div>
                            <h2 style={{ marginTop: "20px", color: "white" }}><b>Risk Free Purchase</b></h2>
                            <ul style={{ fontSize: "25px", marginTop: "17px", color: "white" }} >
                                <i className="bi bi-check-lg"> Money Back Guarantee</i><br />
                                <i className="bi bi-check-lg">Full Support </i><br />
                                <i className="bi bi-check-lg">Services Offered </i>
                            </ul>
                        </div>
                        {/*<button type="button" className="btn btn btn-lg ms-5  rounded-pill " style={{ backgroundColor: "#0fb0cd" }}>Learn More</button>*/}
                    </div>
                    <div className="col-md-6">
                        <img src="/images/laptop.png" className="w-100 imgup" alt="A Laptop" />
                    </div>
                </div>
            </div>
            <div className="fix">

                <div className="container-fluid">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-md-4">
                                <h2>Your Digital Partner</h2>
                                <ul style={{ fontSize: "20px" }} >
                                    <i className="bi bi-check-lg"> Expert Licensing Advisors </i><br />
                                    <i className="bi bi-check-lg">Fluent in Microsoft </i><br />
                                    <i className="bi bi-check-lg">Implementation Services Available</i>
                                </ul>

                            </div>
                            <div className="col-md-3 text-center kb">
                                <div className="row">
                                    <div className="align-items-center">
                                        <img src="/images/people.png" className="w-100" alt="A group of people" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/*
                <div className="container-fluid p-5">
                    <div className="container border border-3 rounded-3 mt-5 pt-5 pb-5">
                        <div className="row justify-content-evenly g-5">
                            <div className="col-md-3 border-end text-center">
                                <h3><b>No</b></h3>
                                <p>Commitment </p>
                            </div>
                            <div className="col-md-3 border-end text-center">
                                <h3><b>Discounted</b></h3>
                                <p>Both Annually/Monthly</p>
                            </div>
                            <div className="col-md-3 border-end text-center">
                                <h3><b>Renewal</b></h3>
                                <p>Loyalty Offers</p>
                            </div>
                            <div className="col-md-3  text-center">
                                <h3><b>Fully</b></h3>
                                <p>Transferable</p>
                            </div>
                        </div>
                    </div>
                </div>
                */}
            </div>
            <ContactContainer />
        </>
    )
}

function ContactContainer() {
    return (
        <>
            <div style={{ height: "550px" }} className="d-flex justify-content-center bg-white">
                <div className="me-2 w-50">
                    <div className="h-100 d-flex justify-content-end">
                        <div style={{ maxWidth: "550px" }}>
                            <img style={{ width: "100%", height: "550px", objectFit: "cover" }} src="/images/getintouch.webp" />
                        </div>
                    </div>
                </div>
                <div className="ms-2 w-50">
                    <div className="d-flex flex-column justify-content-between h-100">
                        <div>
                            <b className="display-2" style={{ fontWeight: 700 }}>Get In Touch</b>
                            <p>Contact us for any of these services through phone and email</p>
                        </div>
                        <div className="text-start">
                            <b className="support-text-blue" style={{ fontSize: "18pt" }}>Main Office</b>
                            <p>Pasadena, CA</p>
                            <b className="support-text-blue" style={{ fontSize: "18pt" }}>Email Address</b>
                            <p><EmailLink /></p>
                            <b className="support-text-blue" style={{ fontSize: "18pt" }}>Phone Number</b>
                            <p><a className="text-dark" href="tel: +1 (213) 459-5858">+1 (213) 459-5858</a></p>
                        </div>
                    </div>
                </div>
            </div>
            <br />
            <br />
            <br />
        </>
    );
}

function SearchInput({ search }: { search: [string, (newValue: string) => void] } ) {
    return (
        <div className="input-group" style={{ maxWidth: "250px" }}>
            <div className="input-group-prepend">
                <span className="input-group-text" style={{
                    borderTopRightRadius: 0, // Why do i have to add these manually??? bootstrap lol
                    borderBottomRightRadius: 0,
                }}>&#128269;</span>
            </div>
            <input type="search" className="form-control" value={search[0]} onChange={(e) => {
                search[1](e.currentTarget.value.toLowerCase());
            }} />
        </div>
    );
}